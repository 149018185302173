import React from 'react';
import { Loader } from '@axeedge/go-teacher-components';
import NoAdminRights from './NoAdminRights';
import PurchaseForm from './PurchaseForm';
import { useQuery } from '@apollo/react-hooks';
import { GET_PROMO_CLASSES } from '../services/graphql';

const Purchase = ({ onBack, currentUser, currentPromo }) => {

    const { data, loading } = useQuery(GET_PROMO_CLASSES, {
        variables: {
            schoolId: currentUser.school.id
        }
    });

    if (currentUser && !currentUser.isSchoolAdmin) {
        return <NoAdminRights onBack={onBack} schoolId={currentUser.school.id} />
    }

    return (
        <>
            {loading ? <Loader /> : data && data.school && (
                <PurchaseForm
                    currentUser={currentUser}
                    onBack={onBack}
                    currentPromo={currentPromo}
                    school={data.school} />
            )}
        </>
    )
}
export default Purchase;