import React, { Fragment, useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Printer } from 'react-feather';
import { AuthContext } from '../../services/auth/AuthProvider';
import { navigate } from '@reach/router';
import { Loader } from '@axeedge/go-teacher-components';

import PackList from './scenes/PackList';
import { GET_CLASS_QUERY } from '../../services/class/graphql';
import PrintableStudentsList from './scenes/Students/components/PrintableStudentsList';

const Class = ({ classId, location }) => {

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const [showPrintableList, setShowPrintableList] = useState(false);
    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: classId
        }
    });

    const clearDeleted = () => {
        navigate(`/class/${classId}`, {
            replace: true,
            state: {deleted: false,}
          })
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass) {
        if (showPrintableList) {
            return <PrintableStudentsList school={teacher.school} classId={data.studentsClass.id} exitPrintMode={() => setShowPrintableList(false)} />
        }
        return (
            <Fragment>
                <div className='page-header'>
                    <div className='page-header__content'>
                        <div className='page-header__content__inner'>
                            <h2 className='page-header__content__title'>{data.studentsClass.name} &ndash; Year {data.studentsClass.description} </h2>
                            <p className='page-header__content__link' onClick={() => setShowPrintableList(true)}><Printer size="10" /> Show Printable Pupils List</p>
                        </div>
                    </div>
                </div>
                {location && location.state && location.state.deleted && <p onClick={() => clearDeleted()}>Task Deleted!</p>}
                {/* Need to show packs if there are packs, plus the writing activity button */}
                <PackList classId={data.studentsClass.id} packs={data.studentsClass.classPacks} />
                {/* {
                    !!data.studentsClass.classPacks.length ? (
                        
                    ) : (<p>No packs assigned to this class</p>)
                } */}
            </Fragment>
        );
    }

    return null;
}

export default Class;