import { gql } from 'apollo-boost';

export const GET_CLASS_PACK = gql`
query classPack($classPackId: ID!) {
    classPack(classPackId: $classPackId) {
        id
        packOfferIndex
        isPromo
        bookTemplate {
            id
            name
            isSingleChapter
        }
        bookTemplateCategory {
            id
            name
            description
            bookTemplates {
                id
                name
                description
                isSingleChapter
                coverUrl
                previewUrl
                resourcesUrl
                isPromo
                isWriteToRaise
            }
        }
        purchasedPack {
            id
            instances
            academicYear
            status
            maxNumberOfStudents
        }
        book {
            id
            isSingleChapter
            status 
        }
        studentsClass {
            id
        }
    }
}
`;

export const GET_BOOK_TEMPLATE_CATEGORIES = gql`
query bookTemplateCategories {
    bookTemplateCategories {
        id
        description
        name
        bookTemplates {
            id
            name
            coverUrl
            description
            isSingleChapter
            foreword
            chapters {
                id
            }
            resources {
                id
                name
                url
                resourceType
            }
        }
    }
}
`;

export const CHOOSE_BOOK_MUTATION = gql`
mutation assignBookToClassPack($classPackId: ID!, $bookTemplateId: ID!, $bookTemplateCategoryId: ID!) {
    assignBookToClassPack(classPackId: $classPackId, bookTemplateId: $bookTemplateId, bookTemplateCategoryId: $bookTemplateCategoryId) {
        classBook {
            id
        }
        errors
    }
}
`;

