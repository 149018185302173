// parse purchased packs status (to display purchased packs as class instance packs)
export const parseOfferStatus = data => {
    let offerDetails = [];
    Object.entries(JSON.parse(data)).map(e => offerDetails.push({
        id: +e[0],
        students: e[1].counter,
        students_class: e[1].students_class
    }))
    return offerDetails
}


// check the current academic year 
export const isCurrentAcademicYear = academicYear => {
    const END_MONTH = 6;
    const currYear = new Date().getFullYear()
    const currMonth = new Date().getMonth();
    const testYear = currMonth < END_MONTH ? currYear - 1 : currYear;
    return +academicYear.substring(0, 4) === testYear;
}


// Classes: get available packs (for the current academic year)
export const getAvailablePacks = (purchasedPacks, classId) => {
    const classPacks = [];
    const currentPurchasedPacks = purchasedPacks.filter(pPack => pPack.academicYear && isCurrentAcademicYear(pPack.academicYear));
    currentPurchasedPacks.forEach(pPack => {
        parseOfferStatus(pPack.status).forEach(item => {
            if (!item.students_class) {
                classPacks.push({
                    id: `${pPack.id}-${item.id}`,
                    packOfferName: pPack.packOffer.name,
                    packOfferId: pPack.packOffer.id,
                    packOfferIndex: item.id,
                    purchasedPackId: pPack.id,
                    purchasedAt: pPack.purchasedAt,
                    maxNumberOfStudents: item.students,
                    academicYear: pPack.academicYear,
                    cls: item.students_class
                })
            }
        })
    })
    return classPacks;
}