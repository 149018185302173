import { gql } from 'apollo-boost';

export const AWARD_GEM_MUTATION = gql`
mutation addRewardGemToStudent($studentId: ID!) {
    addRewardGemToStudent(studentId: $studentId) {
        student {
            id
        }
        errors
    }
}
`;