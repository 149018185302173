import React, { Fragment } from 'react';
import cx from 'classnames';
import _ from 'lodash';

import { Button } from '@axeedge/go-teacher-components';

import Output from '../../../../../../../../components/EditorJsOutput';
import styles from './PrintableEntries.module.scss';

const PrintableEntries = ({ chapter }) => {
    return (
        <Fragment>
            <div className={styles.studentsPrint}>
                <div className={cx(styles.noPrint, 'hidden-print')}>
                    <div className={styles.noPrintContent}>
                        <p className='u-m-base-2'>You can print this view to get a copy of each pupil's entry for this chapter.</p>
                        <Button onClick={() => window.print()}>Print</Button>
                    </div>
                </div>
                {
                    _.sortBy(chapter.studentEntries, 'author.lastName', ['desc']).map(entry => {
                        if (entry.text) {
                           return (
                                <div key={`print-${entry.author.id}`} className={cx(styles.student, styles.printSheet)}>
                                    <p className={styles.bookTitle}>{chapter.classBook.title}</p>
                                    <p className={styles.chapterTitle}>{chapter.title}</p>
                                    <p className={styles.author}>{entry.author.displayName}</p>
                                    <Output data={JSON.parse(entry.teacherEdit || entry.text)} />
                                </div>
                            ) 
                        }
                        return null;
                    })
                }
            </div>
            
        </Fragment>
        
    );
}

export default PrintableEntries;
