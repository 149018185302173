import React, { Fragment } from 'react';
import { Slash } from 'react-feather';

import StudentsEntriesList from '../../StudentsEntriesList';
// import Tip from '../../../../../components/Tip';

const VotingClosed = ({ chapter }) => {
    return (
        <Fragment>
            <h1 className='u-m-base-3'>StarCasting Ended</h1>
            <p className='u-m-base-3'>Pupils are no longer able to StarCast but we won't show them the winner.</p>
            {/* <Tip title='Tip:' text='Swap to projector mode to show a countdown to winner timer' /> */}
            <h4>Results</h4>
            <StudentsEntriesList 
                onlyEligible={true}
                ranked={true} 
                votes={true}
                status={chapter.status} 
                entries={chapter.studentEntries} 
            />
            {chapter.studentEntries.filter(entry => {return !entry.eligibleToWin && !entry.hiddenToCasting}).length !== 0 && (
                <Fragment>
                    <p className='icon-title u-m-top-4 u-m-base-1'><Slash className='u-m-right-1' /> Uneligible Entries shown in StarCasting</p>
                    <StudentsEntriesList 
                        onlyUnEligible={true}
                        status={chapter.status}
                        entries={chapter.studentEntries}
                        votes={true}
                    />
                </Fragment>
            )}
        </Fragment>
    )
}

export default VotingClosed;
