import React, { useEffect, useState, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import { XCircle } from 'react-feather';
import { Button } from '@axeedge/go-teacher-components';

import { EDIT_BOOK_CHAPTER } from '../../../services/graphql';
import { updateChapter } from '../../../services/utils';
import SaveConfirm from '../../../../../components/SaveConfirm';
import styles from '../Chapter.module.scss';

const EditGuidance = ({ chapter }) => {

    const [chapterGuidance, setChapterGuidance] = useState(chapter.guidance || chapter.description);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [vocabs, setVocabs] = useState(chapter.vocabularyWords);

    const schema = Yup.object().shape({
        guidance: Yup.string()
            .required('Please enter some guidance')
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema
    });

    useEffect(() => {
        setValue('guidance', chapterGuidance);
    }, [setValue, chapterGuidance]);

    const tagInput = useRef(null);

    const addVocabs = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (vocabs.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            setVocabs([...vocabs, val.trim()]);
            tagInput.current.value = null;
        }
    };

    const removeVocabs = i => {
        const newWords = [...vocabs];
        newWords.splice(i, 1);
        setVocabs(newWords);

    };

    useEffect(() => {
        setValue('vocabsInput', vocabs);
    }, [setValue, vocabs]);

    const client = useApolloClient();

    const [editChapter, { loading }] = useMutation(EDIT_BOOK_CHAPTER, {
        onCompleted: ({ editBookChapter }) => {
            updateChapter(editBookChapter, client);
            setShowSaveConfirm(true);
        }
    })

    const onSubmit = values => {
        const { guidance, vocabsInput } = values;
        editChapter({
            variables: {
                bookChapterId: chapter.id,
                title: chapter.title,
                description: chapter.description,
                content: chapter.content,
                guidance,
                vocabularyWords: !vocabsInput ? [] : vocabsInput.split(',')
            }
        });
    }
    return (
        <>
            {
                !chapter.classBook.classPack.isWritingActivity && (
                    <div className={styles.editGuidance}>
                        <h4 className='u-text-left u-m-top-2'>Edit Guidance</h4>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='basic-form__group'>
                                <TextareaAutosize
                                    name='visibleGuidance'
                                    onChange={e => setChapterGuidance(e.target.value)}
                                    value={chapterGuidance}
                                    placeholder='Chapter guidance (required)'
                                    className='basic-form__text-box'
                                />
                                <input type='hidden' name='guidance' ref={register} defaultValue={chapter.guidance} />
                            </div>
                            {errors.guidance && <p className='basic-form__hint'>{errors.guidance.message}</p>}

                            <div className='basic-form__group--horizontal'>
                                <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='word'>Word bank</label>
                                <input
                                    type="text"
                                    placeholder='Separate words with comma'
                                    className='basic-form__text-box'
                                    onKeyPress={(e) => addVocabs(e)}
                                    ref={tagInput}
                                />
                            </div>
                            <div className={styles.vocabField}>
                                {vocabs.map((vocab, i) => {
                                    return (
                                        <span className={styles.vocabItem} key={`vocab${i}`}>
                                            {vocab}
                                            <button className={styles.vocabItemDelete} type="button" onClick={() => removeVocabs(i)}><XCircle size="18" /></button>
                                        </span>
                                    )
                                })}
                                <input type='hidden' name='vocabsInput' ref={register} defaultValue={chapter.vocabularyWords.length > 0 && chapter.vocabularyWords.join(',')} />
                            </div>

                            <Button outline type='submit' disabled={loading || !chapterGuidance}>
                                {loading ? 'Saving...' : 'Save Changes'}
                            </Button>
                        </form>
                        {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} />}
                    </div>
                )
            }

        </>
    )
}

export default EditGuidance;