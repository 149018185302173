import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import AboutBookContentYearEnd from './components/AboutBookContentYearEnd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { navigate, Link } from '@reach/router';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import Purchase from './components/Purchase';
import { ADD_PROMO_PACK_MUTATION } from '../Class/scenes/PromoBook/services/graphql';
import { GET_CLASS_QUERY } from '../../services/class/graphql';
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import { useLocation } from 'react-use';
import cx from 'classnames';
import styles from './Promo.module.scss';
import { ArrowLeft } from 'react-feather';
import { GET_SCHOOL_PACKS, ASSIGN_PURCHASED_PACK_AND_BOOK_TO_CLASS } from './services/graphql';
import { getAvailablePacks } from './services/utils';

const PromoContent = ({ currentPromo }) => {

    const auth = useContext(AuthContext);
    const { currentUser } = auth;
    const locationState = useLocation();
    const [availablePacks, setAvailablePacks] = useState([]);

    const [purchase, setPurchase] = useState(false);

    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedClassForPack, setSelectedClassForPack] = useState(locationState?.state?.classId || null);
    const [packSelectedForClass, setPackSelectedForClass] = useState(null);
        
    const [formErrors, setFormErrors] = useState([]);
    const [formErrorsPack, setFormErrorsPack] = useState([]);


    const closest = (arr, closestTo) => {
        let closest = Math.max.apply(null, arr); //Get the highest number in arr in case it match nothing.
        for (var i = 0; i < arr.length; i++) { //Loop the array
            if (arr[i] >= closestTo && arr[i] < closest) closest = arr[i]; //Check if it's higher than your number, but lower than your closest value
        }
        return closest;
    }

    useEffect(() => {
        if (selectedClassForPack && availablePacks && currentUser) {
            const selectedCls = currentUser.studentsClasses.find(c => c.id === selectedClassForPack);
            if (selectedCls) {
                const closestPack = closest(availablePacks.map(p => p.maxNumberOfStudents), selectedCls.studentsCounter);
                setPackSelectedForClass(availablePacks.find(p => p.maxNumberOfStudents == closestPack)?.id)
            }
        }
    }, [selectedClassForPack, availablePacks])


    const { data } = useQuery(GET_SCHOOL_PACKS, {
        variables: {
            schoolId: currentUser?.school?.id
        },
        onCompleted: (data) => setAvailablePacks(getAvailablePacks(data.school.purchasedPacks))
    });

    const [addPromoPack, { loading: adding }] = useMutation(ADD_PROMO_PACK_MUTATION, {
        onCompleted: ({ addPromoPackToClass }) => {
            if (addPromoPackToClass.errors && addPromoPackToClass.errors.length !== 0) {
                setFormErrors(addPromoPackToClass.errors);
                return;
            } else if (addPromoPackToClass.classBook.id) {
                navigate(`/book/${addPromoPackToClass.classBook.id}`);
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: selectedClass } }]
    });

    const [assignPurchasedPackAndBookToClass, { loading: assigningPack }] = useMutation(ASSIGN_PURCHASED_PACK_AND_BOOK_TO_CLASS, {
        onCompleted: ({ assignPurchasedPackAndBookToClass }) => {
            if (assignPurchasedPackAndBookToClass.errors && assignPurchasedPackAndBookToClass.errors.length !== 0) {
                setFormErrorsPack(assignPurchasedPackAndBookToClass.errors);
                return;
            } else if (assignPurchasedPackAndBookToClass?.classBook?.id) {
               navigate(`/book/${assignPurchasedPackAndBookToClass.classBook.id}`);
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: selectedClassForPack } }]
    });

    return (
        <>
            {purchase ? <Purchase currentPromo={currentPromo} currentUser={currentUser} onBack={() => setPurchase(false)} /> :
                <div className='card card-mobile'>
                    <div className="page-header">
                        <div className="page-header__content">
                            {locationState && locationState.state && locationState.state.classId ? (
                                <Link to={`/class/${locationState.state.classId}`} className="page-header__back u-text-dark"><ArrowLeft className='page-header__back__icon' /></Link>
                            ) : (
                                <a className='page-header__back u-text-dark' href={`${getUrl(APPS.go_portal_teacher)}`}>
                                    <ArrowLeft className='page-header__back__icon' />
                                </a>
                            )}

                            <h2 className="page-header__content__title">{currentPromo.name}</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-8">
                            <AboutBookContentYearEnd book={currentPromo} />
                        </div>
                        <div className="col-md-4">
                            <h2 className='heavy'>Get Started...</h2>
                            <p className='u-m-base-2'>Choose from one of our simple book purchase options:</p>
                            <div className={styles.purchaseRecommended}>
                                <h3 className={styles.purchaseRecommendedTitle}>Class Packs (Recommended)</h3>
                                <p className='u-m-left-2 u-m-right-2 u-text-center'>Use a class pack and ensure every pupil receives a personalised book</p>

                                {data?.school?.purchasedPacks && availablePacks.length > 0 && (
                                    <>
                                        <div className='u-text-center u-m-left-2 u-m-right-2'>
                                            <p className='u-m-base-1'>You have <b>{availablePacks.length}</b> available class packs</p>
                                            <p><a href="https://boomwriter.co.uk/leavers#packs" className='link-underline u-text-dark' target='blank' rel='noopener noreferrer'>more about class packs</a></p>
                                        </div>

                                        <div className={cx(styles.purchaseRecommendedContent, 'u-m-base-1 u-m-top-2')}>
                                            {!locationState.state?.classId && (
                                                <>
                                                    <p className='heavy'>Select Your Class:</p>
                                                    <select className='basic-form__text-select u-m-base-1' onChange={(e) => setSelectedClassForPack(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {currentUser.studentsClasses.map(cls =>
                                                            <option key={`p-${cls.id}`} value={cls.id}>{cls.name}</option>
                                                        )}
                                                    </select>
                                                </>
                                            )}
                                            <p className='heavy'>Select Pack:</p>
                                            <ul className='heavy u-m-base-1'>
                                                {availablePacks.sort((a, b) => a.maxNumberOfStudents - b.maxNumberOfStudents).map(pack => (
                                                    <li className={cx({ [styles.selectPackDisabled]: !selectedClassForPack })} key={pack.id}>
                                                        <input
                                                            className='u-m-right-2'
                                                            disabled={!selectedClassForPack}
                                                            checked={packSelectedForClass === pack.id}
                                                            onChange={(e) => setPackSelectedForClass(e.target.value)}
                                                            type='radio'
                                                            value={pack.id}
                                                            name='availablePacks'
                                                        />
                                                        {pack.maxNumberOfStudents} students
                                                    </li>
                                                ))}
                                            </ul>
                                            <Button
                                                disabled={!packSelectedForClass || assigningPack} 
                                                fullWidth
                                                primary
                                                onClick={() => assignPurchasedPackAndBookToClass({
                                                    variables: {
                                                        studentsClassId: selectedClassForPack,
                                                        purchasedPackId: packSelectedForClass.split('-')[0],
                                                        bookTemplateId: currentPromo.id,
                                                        packOfferIndex: +(packSelectedForClass.split('-')[1])

                                                    }
                                                })}
                                            >Use pack & create book</Button>
                                            {formErrorsPack && formErrorsPack.length > 0 && <FormErrors errors={formErrorsPack} />}
                                        </div>
                                    </>
                                )}

                                <div className={styles.purchaseRecommendedContent}>
                                    <h3 className='heavy'>Purchase more class sets</h3>
                                    <Button fullWidth onClick={() => setPurchase(true)} primary>Purchase class sets</Button>
                                </div>
                            </div>

                            <div className='panelLight'>
                                <h3 className='heavy'>2. Parent purchase</h3>
                                <p className='u-m-base-2'>Send home letter for parents to purchase direct from our secure online store.</p>
                                <select className='basic-form__text-select u-m-base-2' onChange={(e) => setSelectedClass(e.target.value)}>
                                    <option value="">Select</option>
                                    {currentUser.studentsClasses.map(classItem => (
                                        <option value={classItem.id} key={classItem.id}>{classItem.name}</option>
                                    ))}
                                </select>
                                {formErrors && formErrors.length > 0 && <FormErrors errors={formErrors} />}
                                <Button
                                    outline
                                    fullWidth
                                    disabled={!selectedClass || adding}
                                    onClick={() => addPromoPack({
                                        variables: {
                                            studentsClassId: selectedClass,
                                            bookTemplateId: currentPromo.id
                                        }
                                    })}
                                >{adding ? 'Adding' : 'Select'}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default PromoContent;