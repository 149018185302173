import React from "react";
import styles from './WriteToRaiseLetters.module.scss';
import cx from 'classnames';
import logo from '../../../../images/logos/boomwriter-full.svg';
import buyQrImgIndia from '../../../../images/qr-write-buy-india.png';
import beeImg from '../../../../images/bee-badge.png';
import beeAuthor from '../../../../images/bee-author.png';
import beeContent from '../../../../images/bee-book-contents.png'

const IndiaParentLetters = ({ bookData, studentsClass }) => {
    const hostname = window && window.location && window.location.hostname;

    return (
        <>

            {studentsClass.students.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(student => {
                return (
                    <div key={`print-${student.id}`} className={cx(styles.student, styles.printSheet)}>
                        <div className={styles.studentHead}>
                            <div className={styles.studentHeadMain}>
                                <img className={styles.studentHeadLogo} width="100" src={logo} alt='' />
                                <h1 className={cx(styles.studentHeadName, 'u-m-base-0')}>{student.name}</h1>
                                <h2 className={styles.studentHeadPublished}>Is A Published Writer!</h2>
                                <p className='u-m-base-2'>We have completed a published book with <i>Diary of a Wimpy Kid</i> author Jeff Kinney as a part of the National Writing Bee competition. You can now purchase a personalised copy of your child’s book.</p>
                            </div>
                            <div className={styles.studentHeadBrian}>
                                <img src={beeImg} alt='' />
                            </div>
                        </div>
                        <div className={styles.studentFunds}>
                            <div className={styles.studentFundsImg}>
                                <img src={beeAuthor} alt='' />
                            </div>
                            <div className={styles.studentFundsMain}>
                                <h3 className={styles.subtitle}>Started by Jeff Kinney, completed by me!</h3>
                                <p className={styles.lead}><b>The perfect family gift and keepsake.</b></p>
                                <p>Books cost just ₹195 each plus shipping.Please note there is a minimum quantity of 2 copies per order. Please allow at least 30 days for printing and delivery.</p>
                            </div>
                        </div>

                        <div className={styles.studentBook}>
                            <div className={styles.studentBookMain}>
                                <h3 className={styles.subtitle}>What’s in my personalised book?</h3>
                                <ul className={styles.studentBookList}>
                                    <li>My name on the cover</li>
                                    <li>Chapter 1 by Jeff Kinney</li>
                                    <li>Winning chapters</li>
                                    <li>My own ending</li>
                                </ul>
                            </div>
                            <div className={styles.studentBookImg}>
                                <img src={beeContent} alt='' />
                            </div>
                        </div>

                        <div className={styles.studentOrder}>
                            <div className={styles.studentOrderMain}>
                                <h3 className={styles.studentOrderTitle}>Order on our online store</h3>
                                <p>To purchase please visit the BoomWriter book store and enter the unique code for your child's book. <b>Please note books can only be purchased in multiples of two - e.g. 2, 4, 6, etc.</b></p>
                                <div className={styles.studentOrderUrl}>
                                    <img className={styles.studentOrderImg} src={buyQrImgIndia} alt='' />
                                    <div>
                                        <h2 className={styles.studentOrderSubtitle}>boomwriter.co.uk/store</h2>
                                        <p>My book code:</p>
                                        <h2 className={styles.studentOrderSubtitle}>{bookData.bookCode}-{student.storeCode}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </>
    )
}

export default IndiaParentLetters;