import React, { Fragment } from 'react';
import { CheckCircle, PlusCircle } from 'react-feather';

import styles from './StarCastingCriteria.module.scss';

const StarCastingCriteria = ({ chapter, disabled }) => {
    return (
        <Fragment>
            <h4 className='u-m-base-2'>StarCasting settings</h4>
            <div className={styles.criterion}>
                <p>How much do you like this entry?</p>
                <CheckCircle />
            </div>
            { !disabled && (
                <button className={styles.addCriterionButton}>
                    <PlusCircle className={styles.addCriterionButtonIcon} />
                    <p>Add criteria</p>
                </button>
            )}
        </Fragment>
    );
}

export default StarCastingCriteria;
