import React from 'react';
import IsPromo from './scenes/IsPromo';
import IsPurchased from './scenes/IsPurchased';

const Completed = ({ book, setAddBookPack }) => {
    
    return (
        <div className='card card-mobile'>
            {book.classPack.isPromo && !book.classPack.purchasedPack ? <IsPromo setAddBookPack={setAddBookPack} book={book} /> : <IsPurchased book={book} />}
        </div>
    )
}

export default Completed;
