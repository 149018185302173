import React, { Fragment } from 'react';
import { ArrowLeft, CheckCircle } from 'react-feather';
import { Button } from '@axeedge/go-teacher-components';
import cx from 'classnames';
import styles from './EditOrder.module.scss';

const EditOrder = ({
    teacherName,
    school,
    setOrderEdited,
    setReviewEditOrder,
    orderCopies,
    setOrderCopies
}) => {


    let copies = orderCopies;
    const onUpdateCopies = (value, id) => {
        copies = copies.map(el => el.id === id ? {...el, copies: +value} : el);
    }

    const onSaveOrder = () => {
        const updatedCopies = copies.map(el => {
            return {...el, copies: el.copies}
        });
        setOrderCopies(updatedCopies);
        setOrderEdited(true);
        setReviewEditOrder(false);
    }

    return(
        <Fragment>
            <h1 className='icon-title'>
                <button onClick={() => {
                    setReviewEditOrder(false);
                }} className='btn-reset u-p-top-1 u-m-right-1'><ArrowLeft /></button>
                Order details
            </h1>
            <div className="row">
                <div className="col-md-6 u-m-base-3">
                    <ul className='u-m-base-3 list-group'>
                        {
                            orderCopies.map((order, i) => (
                                <li key={`order-${order.id}`} className={cx('list-item', styles.orderListAction, {'u-m-base-3': i === 0})}>
                                    {order.name}
                                    <div className={styles.orderListAction}>
                                        <input 
                                            type="number"
                                            defaultValue={order.copies}
                                            onKeyDown={(e) => e.preventDefault()}
                                            min='1'
                                            onChange={(e) => onUpdateCopies(e.target.value, order.id)}
                                            className={cx('basic-form__text-box', styles.orderListActionInput)}
                                        />
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                    <Button onClick={() => onSaveOrder()} className='icon-title'>
                        <CheckCircle size="18" className='u-m-right-1' /> Save order
                    </Button>
                </div>

                <div className="col-md-6">
                    <h2 className='heavy'>Shipping address</h2>
                    <p className='u-m-base-2'>Books are shipped to your school address:</p>
                    <p>FAO {teacherName}</p>
                    <p>{school.name}</p>
                    <p>{school.address}</p>
                    <p>{school.address2}</p>
                    <p>{school.city}</p>
                    <p>{school.zip}</p>
                    <div className="card-muted u-m-top-3">
                        <h3>Pupil copies</h3>
                        <p>Pupil copies are personalised featuring their name on the cover plus their alternative ending.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditOrder;