import React, { useState } from 'react';
import { CheckCircle, CheckSquare, Edit3, File, FileText, PlusCircle, Star, Slash, Square, EyeOff, Eye } from 'react-feather';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import cx from 'classnames';
import ReactTooltip from "react-tooltip";

import { Modal } from '@axeedge/go-teacher-components';
import { CHAPTER_STATUS, ENTRY_STATUS } from '@axeedge/go-shared-utils';

import EntryModal from '../EntryModal';
import { MARK_ENTRY_ELIGIBLE_MUTATION, UNMARK_ENTRY_ELIGIBLE_MUTATION, MARK_ENTRY_HIDDEN_MUTATION, UNMARK_ENTRY_HIDDEN_MUTATION } from '../../../../services/graphql';

import styles from './StudentEntry.module.scss';
import { updateEntry } from '../../../../services/utils';

const StudentEntry = ({ classes, entry, onSetChecked = null, rank, show, status, bookStatus }) => {
    const [checked, setChecked] = useState(false);
    const [showEntryModal, setShowEntryModal] = useState(false);

    const clickCheckBox = () => {
        if (onSetChecked) {
            onSetChecked(entry);
        }
        setChecked(c => !c);
    }

    const client = useApolloClient();

    const [markAsEligible] = useMutation(MARK_ENTRY_ELIGIBLE_MUTATION, {
        variables: {
            bookChapterStudentEntryId: entry.id
        },
        onCompleted: ({ markChapterEntryAsEligibleToWin }) => {
            updateEntry(markChapterEntryAsEligibleToWin, client);
        }
    });

    const [unmarkAsEligible] = useMutation(UNMARK_ENTRY_ELIGIBLE_MUTATION, {
        variables: {
            bookChapterStudentEntryId: entry.id
        },
        onCompleted: ({ unmarkChapterEntryAsEligibleToWin }) => {
            updateEntry(unmarkChapterEntryAsEligibleToWin, client);
        }
    });

    const [markAsHidden] = useMutation(MARK_ENTRY_HIDDEN_MUTATION, {
        variables: {
            bookChapterStudentEntryId: entry.id
        },
        onCompleted: ({ markChapterEntryAsHiddenToCasting }) => {
            updateEntry(markChapterEntryAsHiddenToCasting, client);
        }
    });

    const [unmarkAsHidden] = useMutation(UNMARK_ENTRY_HIDDEN_MUTATION, {
        variables: {
            bookChapterStudentEntryId: entry.id
        },
        onCompleted: ({ markChapterEntryAsUnhiddenToCasting }) => {
            updateEntry(markChapterEntryAsUnhiddenToCasting, client);
        }
    });

    const toggleEligible = () => {
        if (entry.eligibleToWin) {
            unmarkAsEligible();
        } else {
            markAsEligible();
            if (entry.hiddenToCasting) {
                unmarkAsHidden();
            }
        }
    }

    const toggleVisibleInCasting = () => {
        if (entry.hiddenToCasting) {
            unmarkAsHidden();
        } else {
            if (!entry.eligibleToWin) {
                markAsHidden();
            } 
        }
    }

    return (
        <div className={cx(classes, styles.student, {[styles.studentInactive]: !entry.isActive})}>
            <ReactTooltip />
            <div className={styles.studentRankAndName}>
                {
                    show.checkbox && (
                        <button onClick={() => clickCheckBox()} className={styles.studentCheckbox}>
                            {
                                checked ?
                                <CheckSquare className={styles.studentCheckboxIcon} /> :
                                <Square className={styles.studentCheckboxIcon} />
                            }
                        </button>
                    )
                }
                {show.ranked && <p className={styles.studentRank}>#{rank}</p>}
            <p data-tip='Show Entry' onClick={() => setShowEntryModal(true)} className={styles.studentName}>{entry.author.name}</p>
            </div>
            <div className={styles.studentActions}>
                {
                    show.writing && (
                        <button
                            className={styles.studentAction}
                            data-tip={entry.status === ENTRY_STATUS.submitted ? 'Entry Submitted' : entry.text ? status >= CHAPTER_STATUS.writing_closed ? 'Pupil did not hand in entry' : 'Pupil is Writing' : 'Not Started'}
                            onClick={() => setShowEntryModal(true)}
                        >
                            {
                                entry.status === ENTRY_STATUS.submitted ?
                                <FileText className={styles.studentActionIcon} /> :
                                entry.text ? <Edit3 className={styles.studentActionIcon} /> : <File className={styles.studentActionIcon} />
                            }
                        </button>
                    )
                }
                {
                    show.voted && (
                        <button data-tip={`Has voted ${entry.castedCounter} time(s)`} className={cx(styles.studentAction, styles.studentActionDisabled)}>
                            <Star className={`students-list__student__action__icon ${entry.voted ? 'students-list__student__action__icon--highlight' : ''}`} />
                            <p className={cx(styles.studentActionCount, 'u-m-left-1')}>{entry.castedCounter}</p>
                        </button>
                    )
                }
                {
                    show.votes && (
                        <button data-tip={`Avg score of ${entry.stars} from ${entry.castsCount} votes`} className={cx(styles.studentAction, styles.studentActionDisabled)}>
                            <p className={cx(styles.studentActionCount, 'u-m-right-1')}>{entry.stars}</p>
                            <Star className={`students-list__student__action__icon ${entry.voted ? 'students-list__student__action__icon--highlight' : ''}`} />
                            <p className={cx(styles.studentActionCount, 'u-m-left-1')}>({entry.castsCount || 0})</p>
                        </button>
                    )
                }
                {
                    show.visibleInCasting && (
                        <button
                            className={cx(styles.studentAction, {[styles.studentActionDisabled] : status >= CHAPTER_STATUS.voting})}
                            data-tip={!entry.hiddenToCasting && entry.text ? 'Shown in StarCasting' : 'Not shown in StarCasting'}
                            onClick={() => {
                                if (status >= CHAPTER_STATUS.voting) {
                                    return
                                }
                                if (entry.text) {
                                    toggleVisibleInCasting();
                                }

                            }}
                        >
                            {
                                entry.hiddenToCasting || !entry.text ?
                                <EyeOff className={styles.studentActionIcon} /> :
                                <Eye className={styles.studentActionIcon} />
                            }
                        </button>
                    )
                }
                {
                    show.eligible && (
                        <button
                            className={cx(styles.studentAction, {[styles.studentActionDisabled] : status >= CHAPTER_STATUS.voting})}
                            data-tip={entry.eligibleToWin ? 'Eligible To Win' : 'Not Eligible To Win'}
                            onClick={() => {
                                if (status >= CHAPTER_STATUS.voting) {
                                    return
                                }
                                if (!entry.text) {
                                    return;
                                }
                                toggleEligible();
                            }}
                        >
                            {
                                entry.eligibleToWin ?
                                <CheckCircle className={styles.studentActionIconHighlight} /> :
                                (status < CHAPTER_STATUS.voting && entry.text) ? <PlusCircle className={styles.studentActionIcon} /> : <Slash className={styles.studentActionIcon} />
                            }
                        </button>
                    )
                }
            </div>
            {
                showEntryModal && (
                    <Modal wide closeModal={() => setShowEntryModal(false)}>
                        <EntryModal bookStatus={bookStatus} chapterStatus={status} entry={entry} toggleEligible={toggleEligible} />
                    </Modal>
                )
            }
        </div>
    );
}

export default StudentEntry;
