import React, { useState } from 'react';

import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import ChapterSidebar from './components/ChapterSidebar';
import TeacherChapter from './components/TeacherChapter';
import WritingSetup from './components/WritingSetup';
import Writing from './components/Writing';
import WritingClosed from './components/WritingClosed';
import VotingSetup from './components/VotingSetup';
import Voting from './components/Voting';
import VotingClosed from './components/VotingClosed';
import Completed from './components/Completed';

import styles from './Chapter.module.scss';

const Chapter = ({ chapter, nextChapter, projected, setProjected, setAddBookPack, setShowPrintableList, book }) => {
    const [enabled, setEnabled] = useState(true);

    const getChapterContent = () => {
        if (!chapter) {
            return 'Nothing here'
        }
        switch (chapter.status) {
            case CHAPTER_STATUS.teacher_chapter:
                return <TeacherChapter chapter={chapter} enabled={enabled} setEnabled={setEnabled} />
            case CHAPTER_STATUS.completed:
                return <Completed chapter={chapter} book={book} />;
            case CHAPTER_STATUS.writing_ready:
                return <WritingSetup chapter={chapter} enabled={enabled} setEnabled={setEnabled} />;
            case CHAPTER_STATUS.writing:
                return <Writing chapter={chapter} />;
            case CHAPTER_STATUS.writing_closed:
                return <WritingClosed chapter={chapter} setEnabled={setEnabled} />;
            case CHAPTER_STATUS.voting_ready:
                return <VotingSetup chapter={chapter} />;
            case CHAPTER_STATUS.voting:
                return <Voting chapter={chapter} />;
            case CHAPTER_STATUS.voting_closed:
                return <VotingClosed chapter={chapter} />;
            default:
                return chapter.classBook.isSingleChapter ? 'Not available until book settings are confirmed' : 'Not available until previous chapter is complete';
        }
    }

    return (
        <div className='card card-mobile'>
            <div className={styles.chapter}>
                <div className={styles.chapterContent}>
                    {getChapterContent()}
                </div>
                <ChapterSidebar
                    chapter={chapter}
                    enabled={enabled}
                    nextChapter={nextChapter}
                    projected={projected}
                    setProjected={setProjected}
                    setAddBookPack={setAddBookPack}
                    setShowPrintableList={setShowPrintableList}
                    book={book}
                />
            </div>
        </div>
    );
}

export default Chapter;
