import React from 'react';
import { Link } from '@reach/router';

import styles from './ClassItem.module.scss';
import cx from 'classnames';
import { itemDateColor } from '../../../../services/utils';

const ClassItem = ({ cls }) => {

    return(
        <Link to={`/class/${cls.id}`} className={cx(styles.class, 'col-lg-4 col-md-6')}>
            <div className='card card-hover'>
                <div className={styles.classContent} style={{background: itemDateColor(cls.createdAt)}}>
                    <h2 className={styles.classTitle}>{cls.name}</h2>
                </div>
                <div className='card-footer'>
                    <p className={styles.classSubTitle}>{cls.description}</p>
                </div>
            </div>
        </Link>
    )
}

export default ClassItem;