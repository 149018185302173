import React, { Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import BookHeader from '../../Book/components/BookHeader';
import { ADD_TASK_MUTATION } from '../services/graphql';
import TaskForm from '../components/TaskForm';
import Sidebar from '../components/Sidebar';

import styles from '../Task.module.scss';
import { GET_CLASS_QUERY } from '../../../services/class/graphql';

const CreateTask = ({ classId }) => {
    
    const [addTask, { loading }] = useMutation(ADD_TASK_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: ({ addWritingActivity }) => {
            if (addWritingActivity.classBook && addWritingActivity.classBook.id) {
                navigate(`/task/${addWritingActivity.classBook.id}`, { replace: true });
            } else {
                alert('There was an error, please try again.');
            }
            if (addWritingActivity.errors && addWritingActivity.errors.length !== 0) {
                alert(addWritingActivity.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId } }]
    });

    return (
        <Fragment>
            <BookHeader classId={classId} title={'New Writing Activity'} showBackBtn={true} />
            <div className={styles.task}>
                <div className={styles.taskContent}>
                    <TaskForm loading={loading} onSubmitForm={addTask} task={{ classId, title: '', description: '', resources: [], castingEnabled: true, vocabularyWords: [] }} />
                </div>
                <Sidebar task={null} />
            </div>
        </Fragment>
    )
}

export default CreateTask;