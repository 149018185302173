import React from 'react';
import _ from 'lodash';

import StudentEntry from './components/StudentEntry';

const StudentsEntriesList = ({
    award = false,
    checkbox = false,
    className,
    eligible = false,
    entries,
    onSetChecked,
    onlyEligible = false,
    onlyUnEligible = false,
    ranked = false,
    status,
    bookStatus,
    visibleInCasting = false,
    voted = false,
    votes =  false,
    writing = false
}) => {
    const getStudents = () => {
        let sortedEntries = entries;
        if (onlyEligible) {
            sortedEntries = entries.filter(entry => {return entry.eligibleToWin && !entry.hiddenToCasting});
        }
        if (onlyUnEligible) {
            sortedEntries = entries.filter(entry => {return !entry.eligibleToWin && !entry.hiddenToCasting && entry.text});
        }
        if (!ranked) {
            sortedEntries = _.sortBy(sortedEntries, [function(e) { return !e.isActive }, function(e) { return e.author.lastName; }]);
        }
        return sortedEntries;
    }

    return (
        <div className={className || ''}>
            {
                getStudents().map((entry, i) => {
                    return (
                        <StudentEntry
                            key={`entry-${entry.id}`}
                            entry={entry}
                            onSetChecked={onSetChecked}
                            show={{
                                award,
                                checkbox,
                                eligible,
                                ranked,
                                visibleInCasting,
                                voted,
                                votes,
                                writing
                            }}
                            status={status}
                            bookStatus={bookStatus}
                            rank={i+1}
                        />
                    )
                })
            }
        </div>
    );
}

export default StudentsEntriesList;
