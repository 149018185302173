import React, { useEffect, useState, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import { AlertCircle, XCircle } from 'react-feather';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';

import { EDIT_BOOK_CHAPTER, EDIT_CLASS_BOOK } from '../../../../services/graphql';
import { updateChapter } from '../../../../services/utils';
import SaveConfirm from '../../../../../../components/SaveConfirm';
import styles from './WritingSetup.module.scss';

const WritingSetup = ({ chapter, enabled, setEnabled }) => {

    const { classBook } = chapter;
    const [scEnabled, setScEnabled] = useState(classBook.castingEnabled);
    const [showScModal, setShowScModal] = useState(false);
    const [chapterGuidance, setChapterGuidance] = useState(chapter.guidance);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const [formValues, setFormValues] = useState({
        title: '',
        guidance: '',
        vocabs: chapter.vocabularyWords
    });

    const tagInput = useRef(null);

    const addVocabs = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (formValues.vocabs.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            setFormValues({
                ...formValues,
                vocabs: [...formValues.vocabs, val.trim()]
            });
            tagInput.current.value = null;
        }
    };

    const removeVocabs = i => {
        const newWords = [...formValues.vocabs];
        newWords.splice(i, 1);
        setFormValues({
            ...formValues,
            vocabs: newWords
        });
    };

    const schema = Yup.object().shape({
        guidance: Yup.string()
            .required('Please enter some guidance')
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema
    });

    useEffect(() => {
        if (!chapter.guidance && enabled) {
            setEnabled(false);
        }
        if (chapter.guidance && !enabled) {
            setEnabled(true);
        }
    }, [chapter, enabled, setEnabled])

    useEffect(() => {
        setValue('guidance', chapterGuidance);
    }, [setValue, chapterGuidance]);

    useEffect(() => {
        setValue('vocabsInput', formValues.vocabs);
    }, [setValue, formValues.vocabs]);

    const client = useApolloClient();

    const [editChapter, { loading }] = useMutation(EDIT_BOOK_CHAPTER, {
        onCompleted: ({ editBookChapter }) => {
            updateChapter(editBookChapter, client);
            setShowSaveConfirm(true);
        }
    })

    const [editBook, { loading: savingBookSettings }] = useMutation(EDIT_CLASS_BOOK, {
        onCompleted: ({ editClassBook }) => {
            if (editClassBook.errors && editClassBook.errors.length !== 0) {
                setFormErrors(editClassBook.errors);
                return;
            } else {
                editChapter({
                    variables: {
                        bookChapterId: chapter.id,
                        title: formValues.title,
                        description: chapter.description,
                        content: chapter.content,
                        guidance: formValues.guidance,
                        vocabularyWords: formValues.vocabs
                    }
                });
            }
        }
    });



    const onSubmit = values => {
        const { title, guidance, vocabsInput } = values;
        setFormValues({
            title,
            guidance,
            vocabs: !vocabsInput ? []: vocabsInput.split(',')
        })
        classBook.isSingleChapter ?
            editBook({
                variables: {
                    classBookId: classBook.id,
                    foreword: classBook.foreword,
                    title: classBook.title,
                    castingEnabled: scEnabled,
                }
            }) :
            editChapter({
                variables: {
                    bookChapterId: chapter.id,
                    title,
                    description: chapter.description,
                    content: chapter.content,
                    guidance,
                    vocabularyWords: !vocabsInput ? [] : vocabsInput.split(',')
                }
            });
    }

    return (
        <div className="chapter__writing-setup">
            <h1 className="title">Waiting to start...</h1>
            <p>Let’s setup your chapter. Please add some guidance notes for your pupils. When you’re done, hit start writing. This will then open the writing activity for your pupils.</p>
            <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group--horizontal'>
                    <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='title'>Chapter Title</label>
                    <input
                        name='title'
                        defaultValue={chapter.title}
                        placeholder='Chapter Title (optional)'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>

                {errors.title && <p className='basic-form__hint'>{errors.title.message}</p>}
                <div className='basic-form__group--horizontal'>
                    <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='visibleGuidance'>Guidance</label>
                    <TextareaAutosize
                        name='visibleGuidance'
                        onChange={e => setChapterGuidance(e.target.value)}
                        value={chapterGuidance}
                        placeholder='Chapter guidance (required)'
                        className='basic-form__text-box'
                    />
                    <input type='hidden' name='guidance' ref={register} defaultValue={chapter.guidance} />
                </div>
                {errors.guidance && <p className='basic-form__hint'>{errors.guidance.message}</p>}

                <div className='basic-form__group--horizontal'>
                    <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='word'>Word bank</label>
                    <input
                        type="text"
                        placeholder='Separate words with comma or enter'
                        className='basic-form__text-box'
                        onKeyPress={(e) => addVocabs(e)}
                        ref={tagInput}
                    />
                </div>
                <div className={styles.vocabField}>
                    {formValues.vocabs.map((vocab, i) => {
                        return (
                            <span className={styles.vocabItem} key={`vocab${i}`}>
                                {vocab}
                                <button className={styles.vocabItemDelete} type="button" onClick={() => removeVocabs(i)}><XCircle size="18" /></button>
                            </span>
                        )
                    })}
                    <input type='hidden' name='vocabsInput' ref={register} defaultValue={chapter.vocabularyWords.join(',')} />
                </div>

                {classBook.isSingleChapter &&
                    <div className='basic-form__group u-m-top-3'>
                        <label className='basic-form__text-label basic-form__text-label--auto heavy'>Enable StarCasting</label>
                        <input disabled={classBook.status && classBook.status >= CHAPTER_STATUS.voting} className='switch' type='checkbox' id='switch' checked={scEnabled} onChange={(e) => setScEnabled(!scEnabled)} value={scEnabled} />
                        <label className='switchLabel' htmlFor='switch'>Enable StarCasting</label>
                        {<AlertCircle className='u-link u-m-left-2' onClick={() => setShowScModal(true)} />}
                    </div>
                }
                <Button type='submit' className='u-m-right-2' primary disabled={loading || !chapterGuidance}>
                    {(loading || savingBookSettings) ? 'Saving...' : 'Save Changes'}
                </Button>
            </form>
            {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} />}
            {formErrors && <FormErrors errors={formErrors} />}
            {
                showScModal && (
                    <Modal closeModal={() => setShowScModal(false)}>
                        <p className="u-m-base-2">Starcasting is the name we give to our peer review system. You have full control over which entries will be included (pupils will not know if you chose to exclude their work).</p>
                        <p>Pupils are shown a single anonymised entry at a time and asked to give the piece a star rating based on how well they liked it. Pupils can review multiple pieces. The class winner is the entry with the highest average star rating.</p>
                    </Modal>
                )
            }
        </div>
    );
}

export default WritingSetup;
