import React, { useState, createContext } from 'react';
import ls from 'local-storage';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    const onSetCurrentUser = userData => {
        setCurrentUser(userData);
        if (userData !== null) {
            ls('go_teacher_user', JSON.stringify(userData));
        }
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                setCurrentUser: onSetCurrentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}