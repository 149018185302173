import React from 'react';
import { Router } from '@reach/router';
import TokenCheck from './TokenCheck';
import Dashboard from '../../Dashboard';
import Pack from '../../Pack';
import Book from '../../Book';
import Class from '../../Class';
import CreateTask from '../../Task/scenes/CreateTask';
import Task from '../../Task';
import Promo from '../../Promo';

const AppRouter = () => {
    return (
        <Router>
            <TokenCheck path='/'>
                <Book path='/book/:classBookId' />
                <Pack path='/pack/:classPackId' />
                <Class path='/class/:classId'/>
                <CreateTask path='/task/new/:classId' />
                <Task path='/task/:classBookId' />
                <Promo path='/promo/:promoId' />
                <Promo path='/promo/:promoId/impersonate' />
                <Dashboard path='/' default/>
            </TokenCheck>
        </Router>
    )
}

export default AppRouter;