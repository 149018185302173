import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import EditorJs from 'react-editor-js';

import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { Button } from '@axeedge/go-teacher-components';

import { EDIT_BOOK_CHAPTER } from '../../../../services/graphql';
import { updateChapter } from '../../../../services/utils';
import Output from '../../../../../../components/EditorJsOutput';
import SaveConfirm from '../../../../../../components/SaveConfirm';

import styles from './TeacherChapter.module.scss';

const TeacherChapter = ({ chapter, enabled, setEnabled }) => {
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);

    const resetChapterContent = () => {
        try {
            return JSON.parse(chapter.content);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: chapter.content
                        }
                    }
                ]
            }
        }
    }

    const [chapterContent, setChapterContent] = useState(resetChapterContent());

    const editorJsRef = useRef(null);

    const schema = Yup.object().shape({
        title: Yup.string()
            .required('Please enter a chapter title'),
        content: Yup.string()
            .required('Please enter some chapter content')
    });

    const { register, handleSubmit, errors, reset, setValue } = useForm({
        validationSchema: schema
    });

    useEffect(() => {
        if (chapterContent.blocks.length === 0 && enabled) {
            setEnabled(false);
        }
        if (chapterContent.blocks.length !== 0 && !enabled) {
            setEnabled(true);
        }
    }, [chapterContent, enabled, setEnabled])

    const client = useApolloClient();

    const [editChapter, { loading }] = useMutation(EDIT_BOOK_CHAPTER, {
        onCompleted: ({ editBookChapter }) => {
            updateChapter(editBookChapter, client);
            setShowSaveConfirm(true);
        },
    })

    const onSubmit = values => {
        const { title, content } = values;
        editChapter({
            variables: {
                bookChapterId: chapter.id,
                title,
                description: chapter.description,
                content,
                guidance: chapter.guidance
            }
        });
    }

    const onEditorChange = useCallback(async () => {
        const editorData = await editorJsRef.current.save();
        setValue('content', JSON.stringify(editorData));
        setChapterContent(editorData);
    }, [editorJsRef, setValue, setChapterContent]);

    return (
        <Fragment>
            {
                chapter.classBook.status < BOOK_STATUS.printOrdered ? (
                    <Fragment>
                        <h1>First Chapter</h1>
                        <p>Feel free to edit the first chapter of the book{(!chapter.classBook.currentChapter || (chapter.classBook.currentChapter === chapter.orderid)) ? ` before continuing. When ready, click the 'Start Chapter 2' button to move on.` : '.'} </p>
                        <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                            <div className='basic-form__group'>
                                <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='title'>Title</label>
                                <input
                                    name='title'
                                    defaultValue={chapter.title}
                                    placeholder='Chapter Title (optional)'
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='text'
                                />
                            </div>
                            {errors.title && <p className='basic-form__hint'>{errors.title.message}</p>}
                            <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='visibleContent'>Content</label>
                            <div className={styles.editorHolder}>
                                <EditorJs
                                    data={chapterContent}
                                    placeholder='Initial chapter content (required)'
                                    instanceRef={instance => (editorJsRef.current = instance)}
                                    enableReInitialize={false}
                                    minHeight='0'
                                    onChange={onEditorChange}
                                    logLevel='WARN'
                                />
                            </div>
                            <input type='hidden' name='content' ref={register} defaultValue={JSON.stringify(chapterContent)} />
                            {chapterContent.blocks.length === 0 && <p className='basic-form__hint'>Please enter some chapter content</p>}
                            <Button disabled={loading || chapterContent.blocks.length === 0} type='submit' className='u-m-right-2'>
                                {loading ? 'Saving...' : 'Save Changes'}
                            </Button>
                            <Button 
                                outline
                                onClick={() => {
                                    reset({ 
                                        title: chapter.title, 
                                        content: JSON.stringify(resetChapterContent()) 
                                    });
                                    setChapterContent(resetChapterContent());
                                    editorJsRef.current.clear();
                                    editorJsRef.current.render(resetChapterContent());
                                }}
                                type='button'
                            >Cancel</Button>
                        </form>
                        {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} />}
                    </Fragment>
                ) : (
                    <Fragment>
                        <h1 className='u-m-base-2'>{chapter.title}</h1>
                        <Output data={chapterContent} />
                    </Fragment>
                )
            }
            
        </Fragment>
    )
}

export default TeacherChapter;