import { useContext, useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useLocation } from 'react-use';

import { getUrl, APPS, TERMS_VERSION } from '@axeedge/go-shared-utils';

import { FORCED_LOGOUT } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';

import { APP_NAME } from '../../../services/constants';

const RouteCapture = () => {

    const [targetRoute, setTargetRoute] = useState('');
    const client = useApolloClient();
    const { data } = useQuery(FORCED_LOGOUT);
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const locationState = useLocation();

    const forceLogout = () => {
        ls.clear();
        client.clearStore();
        setCurrentUser(null);
        window.location.href = `${getUrl(APPS.go_portal_teacher)}/redirectAfterLogin/${APP_NAME}?targetUrl=${targetRoute}`;
    }

    useEffect(() => {
        setTargetRoute(locationState.pathname);
    }, [setTargetRoute, locationState]);

    useEffect(() => {
        if (ls('go_teacher_user') && ls('go_teacher_user') === 'undefined') {
            forceLogout(false);
        }
        if (currentUser && !currentUser.lastTermsVersionApproved || currentUser && parseInt(currentUser.lastTermsVersionApproved) < TERMS_VERSION) {
            window.location.href = `${getUrl(APPS.go_portal_teacher)}`;
        }
    });

    if (data && data.forcedLogout) {
        forceLogout();
    }

    return null;
}

export default RouteCapture;
