import React, { Fragment, useCallback, useRef, useState } from 'react';
import EditorJs from 'react-editor-js';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import ImageFadeIn from 'react-image-fade-in';
import { CheckSquare, CheckCircle, Square, Printer, AlertCircle } from 'react-feather';
import { BOOK_STATUS, BOOK_ORDER_TYPE } from '@axeedge/go-shared-utils';
import { READY_TO_PRINT_CLASS_BOOK, GET_CLASS_BOOK, EDIT_CLASS_BOOK } from '../../../../services/graphql';
import brianImg from '../../../../../../images/book/brian_circle.png';
import brianCutoutImg from '../../../../../../images/book/brian-cutout.png';
import wtrCover from '../../../../../../images/book/wtr-book-cover.png';
import wtrLogo from '../../../../../../images/book/wtr-logo.png';
import WtrAdditionalCopies from './WtrAdditionalCopies';
import cx from 'classnames';
import styles from './IsPromo.module.scss';

const IsPromo = ({ book, setAddBookPack }) => {


    const [formErrors, setFormErrors] = useState([]);
    const [promoExtraCopies, setPromoExtraCopies] = useState(false);

    const [review, setReview] = useState({
        names: false,
        sendLetters: false,
        allEdits: false,
        happy: false
    });


    const [confirmPrint, setConfirmPrint] = useState();

    const parentOrders = book.printBookOrders.filter(order => order.orderType === BOOK_ORDER_TYPE.parent);
    const classOrder = book.printBookOrders.find(order => order.orderType === BOOK_ORDER_TYPE.class);

    const [readyToPrint] = useMutation(READY_TO_PRINT_CLASS_BOOK, {
        onCompleted: ({ readyToPrintClassBook }) => {
            setConfirmPrint(false);
            if (readyToPrintClassBook.errors && readyToPrintClassBook.errors.length !== 0) {
                setFormErrors(readyToPrintClassBook.errors);
                return;
            }
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: book.id } })
    });


    const resetBookForeword = () => {
        try {
            return JSON.parse(book.foreword);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: book.foreword
                        }
                    }
                ]
            }
        }
    }


    const [formBookErrors, setFormBookErrors] = useState([]);
    const editorJsRef = useRef(null);

    const [bookForeword, setBookForeword] = useState(resetBookForeword());

    const onEditorChange = useCallback(async () => {
        const editorData = await editorJsRef.current.save();
        setBookForeword(editorData);
    }, [editorJsRef, setBookForeword]);


    const [saveSettings, { loading: savingForward }] = useMutation(EDIT_CLASS_BOOK, {
        onCompleted: ({ editClassBook }) => {
            if (editClassBook.errors && editClassBook.errors.length !== 0) {
                setFormBookErrors(editClassBook.errors);
                return;
            }
        }
    });

    const onSaveEdit = useCallback(async () => {
        const savedData = await editorJsRef.current.save();
        if (savedData.blocks.length !== 0) {
            saveSettings({
                variables: {
                    classBookId: book.id,
                    foreword: JSON.stringify(savedData)
                }
            })
        }
    }, [saveSettings, book.id])

    if (promoExtraCopies) {
        return <WtrAdditionalCopies close={() => setPromoExtraCopies(false)} book={book} />
    }

    return (
        <>
            {book.status === BOOK_STATUS.completed && book.bookTemplate.isWriteToRaise && (
                <div className={styles.wtrBanner}>
                    <div className={styles.wtrBannerImg}>
                        <ImageFadeIn src={brianCutoutImg} alt='Brian Blessed' />
                    </div>
                    <div className={styles.wtrBannerContent}>
                        <ImageFadeIn className={styles.wtrBannerLogo} src={wtrLogo} alt='Brian Blessed' />
                        <h1 className={styles.wtrBannerTitle}>Publish This Book!</h1>
                        <h2 className={styles.wtrBannerSubtitle}>
                            Let parents know how to buy the book.<br />
                            Book Orders Close: March 31
                        </h2>
                    </div>
                    <div className={styles.wtrBannerCover}>
                        <ImageFadeIn src={wtrCover} alt='Cover' />
                    </div>
                </div>
            )}
            <div className='row'>
                <div className='col-md-7 u-m-base-3'>
                    {book.status === BOOK_STATUS.completed ? (
                        <Fragment>
                            {book.bookTemplate.isWriteToRaise ? (
                                <>
                                    <h1 className='u-m-base-2'>What do I need to do?</h1>
                                    <ul className={cx('u-m-base-3', styles.wtrTodo)}>
                                        <li><CheckCircle />
                                            <div>
                                                <p>Print and send home letters so parents know how they can purchase their books.</p>
                                                <a
                                                    href={`/book/${book.id}?printParentLetters=true`}
                                                    className='u-m-top-2 icon-title hidden-print'
                                                    target='_blank'
                                                    rel="noopener noreferrer"
                                                >
                                                    <Button outline className='icon-title'><Printer size='20' className='u-m-right-1' />Parent letters</Button>
                                                </a>
                                            </div>
                                        </li>
                                        <li><CheckCircle /><p>Write the foreword for the book (see below)</p> </li>
                                        <li><CheckCircle /><p>Ensure you are happy with all the pupil entries (see below)</p></li>
                                    </ul>
                                    {!classOrder ? (
                                        <>
                                            <h2 className='h1'>Confirm & Order Additional Copies</h2>
                                            <p className='u-m-base-2'>You can order additonal copies of any pupil’s book at the reduced rate of £3 each. We’ll either invoice your school or deduct this from the amount raised by parent sales</p>
                                            <Button onClick={() => setPromoExtraCopies(true)} className='u-m-base-2'>Purchase Additional copies</Button>
                                        </>
                                    ) : <p className='u-m-base-2 heavy'>Additional copies ordered!</p>}

                                    <h2 className='h1'>Close Orders & Print</h2>
                                    <p className='u-m-base-1'><b>Please ensure all parent orders are in before you publish the book.</b> Any orders made after you publish will not count towards your fund-raising total.</p>
                                    <p className='u-m-base-2'>When all your school's Write to Raise books are published, we'll print and send the complete set to your school.</p>
                                </>
                            ) :
                                <>
                                    <h1>Publish This Book!</h1>
                                    <div className={styles.intro}>
                                        <div className={styles.introIcon}>
                                            {parentOrders.length > 0 ? <AlertCircle /> : <CheckCircle />}
                                        </div>
                                        <div className={styles.introBody}>
                                            {parentOrders.length > 0 ? (
                                                <h4 className='heavy'>
                                                    Parents are waiting for you to publish this book! <br />
                                                    Parent book orders: {parentOrders.length}
                                                </h4>
                                            ) : <h4 className='heavy'>Send parent letters</h4>}
                                            <p className='u-m-top-2'>Print and send home letters so parents know how to purchase their books.</p>
                                            <a
                                                href={`/book/${book.id}?printParentLetters=true`}
                                                className='u-m-top-2 icon-title hidden-print'
                                                target='_blank'
                                                rel="noopener noreferrer"
                                            >
                                                <Button outline className='icon-title'><Printer size='20' className='u-m-right-1' />Parent letters</Button>
                                            </a>
                                        </div>
                                    </div>
                                    <p className='u-m-base-3'>We won't print any parent orders until you publish this book. Please take a few moments to review the checklist and when you are ready publish this book.</p>
                                </>
                            }

                            <div className={styles.printCheckWrapper}>
                                <h3 className='heavy'>Foreword</h3>
                                <div className={cx(styles.printForeword, 'u-m-base-3')}>
                                    <EditorJs
                                        data={bookForeword}
                                        placeholder='Book foreword (required)'
                                        instanceRef={instance => (editorJsRef.current = instance)}
                                        enableReInitialize={false}
                                        minHeight='0'
                                        logLevel='WARN'
                                        onChange={onEditorChange}
                                    />
                                    <Button className='u-m-top-2' outline disabled={savingForward} onClick={() => onSaveEdit()}>{savingForward ? 'Saving...' : 'Save'} </Button>
                                    {formBookErrors.length !== 0 && <FormErrors errors={formBookErrors} />}
                                </div>

                                <div className='u-m-top-3'>
                                    <div onClick={() => setReview({ ...review, names: !review.names })} className={styles.completedCheck}>
                                        {review.names ? <CheckSquare /> : <Square />} <p>Pupil names spelt correctly</p>
                                    </div>
                                    <div onClick={() => setReview({ ...review, allEdits: !review.allEdits })} className={styles.completedCheck}>
                                        {review.allEdits ? <CheckSquare /> : <Square />} <p>All desired edits to all entries made</p>
                                    </div>
                                    <div onClick={() => setReview({ ...review, sendLetters: !review.sendLetters })} className={styles.completedCheck}>
                                        {review.sendLetters ? <CheckSquare /> : <Square />} <p>Send home parent letters</p>
                                    </div>
                                    <div onClick={() => setReview({ ...review, happy: !review.happy })} className={styles.completedCheck}>
                                        {review.happy ? <CheckSquare /> : <Square />} <p>I'm happy for my book to be published</p>
                                    </div>
                                </div>
                                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                <div className='u-m-top-3'>
                                    <Button
                                        onClick={() => setConfirmPrint(true)}
                                        disabled={!(review.names && review.sendLetters && review.allEdits && review.happy)}
                                    >
                                        Publish
                                    </Button>
                                </div>
                            </div>
                            {confirmPrint && (
                                <Modal closeModal={() => setConfirmPrint(false)}>
                                    <div className='u-text-center'>
                                        <h3 className='u-m-base-3'>Are you sure? You can make no changes to the book after clicking to confirm.</h3>
                                        <Button onClick={() => readyToPrint({
                                            variables: { classBookId: book.id }
                                        })} className='u-m-right-2'>Yes</Button>
                                        <Button outline onClick={() => setConfirmPrint(false)}>No</Button>
                                    </div>
                                </Modal>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h1 className='u-m-top-3'>Published!</h1>
                            <p><b>Congratulations, this book is published. Any purchases made by parents will be automatically printed and shipped.</b></p>
                            <h3 className='u-m-top-3 heavy'>Parent letters</h3>
                            <p>Print and send home letters so parents know how to purchase their books.</p>
                            <a
                                href={`/book/${book.id}?printParentLetters=true`}
                                className='u-m-top-2 icon-title hidden-print'
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                <Button outline className='icon-title'><Printer size='20' className='u-m-right-1' />Parent letters</Button>
                            </a>
                        </Fragment>
                    )}
                </div>
                {
                    book.status === BOOK_STATUS.completed && (
                        <div className="col-md-5">
                            {book.bookTemplate.isWriteToRaise ? (
                                <div className={styles.brianCircleSide}>
                                    <ImageFadeIn src={brianImg} alt='' />
                                </div>
                            ) : !book.bookTemplate.isBeeTemplate && (
                                <>
                                    <h2>Purchase book pack</h2>
                                    <p className='u-m-base-3'>It's not too late to purchase a set of books to be delivered direct to your school.</p>
                                    <Button onClick={() => setAddBookPack(true)} className='u-m-base-3'>Add book pack</Button>
                                </>
                            )}
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default IsPromo;