import React, { useContext, useState, Fragment } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
//import EditorJs from 'react-editor-js';
//import Output from '../../../../components/EditorJsOutput';

import { AlertCircle } from 'react-feather';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';
import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import PrintableStudentsList from '../../../Class/scenes/Students/components/PrintableStudentsList';
import SaveConfirm from '../../../../components/SaveConfirm';
import ResourcesList from '../ResourcesList';

import { updateChapter } from '../../services/utils';
import { EDIT_CLASS_BOOK, GET_CLASS_BOOK, START_CHAPTER_MUTATION } from '../../services/graphql';
import cx from 'classnames';
import styles from './BookSettings.module.scss';
import WriteToRaiseBookSettings from './WriteToRaiseBookSettings';

const BookSettings = ({ book, setSelectedChapter, setShowAboutPromo }) => {
    const [formErrors, setFormErrors] = useState([]);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [scEnabled, setScEnabled] = useState(book.castingEnabled);
    const [showScModal, setShowScModal] = useState(false);
    const [showPrintableList, setShowPrintableList] = useState(false);
    const { currentUser } = useContext(AuthContext);


    const schema = Yup.object().shape({
        title: Yup.string()
            .required('Please enter a book title').max(52, 'Title must be 52 characters or less'),
        // foreword: Yup.string()
        //     .required('Please enter a foreword')
    });

    const { register, handleSubmit, errors, reset, watch, setValue } = useForm({
        validationSchema: schema
    });

    const watchTitle = watch('title', book.title);

    // const resetBookForeword = () => {
    //     const bf = JSON.parse(book.foreword);
    //     if (bf) {
    //         return bf
    //     } else {
    //         return {
    //             blocks: []
    //         }
    //     }
    // }
    // const [bookForeword, setBookForeword] = useState(resetBookForeword());

    // const editorJsRef = useRef(null);
    // const onEditorChange = useCallback(async () => {
    //     const editorData = await editorJsRef.current.save();
    //     setBookForeword(editorData);
    //     setValue('foreword', JSON.stringify(editorData));
    // }, [editorJsRef, setBookForeword, setValue]);

    const [saveSettings, { loading: savingSettings }] = useMutation(EDIT_CLASS_BOOK, {
        onCompleted: ({ editClassBook }) => {
            if (editClassBook.errors && editClassBook.errors.length !== 0) {
                setFormErrors(editClassBook.errors);
                return;
            } else {
                setShowSaveConfirm(true);
            }
        }
    });

    const onSubmit = values => {
        const { title } = values;
        saveSettings({
            variables: {
                classBookId: book.id,
                foreword: book.foreword,
                title,
                castingEnabled: scEnabled,
            }
        });
    }

    const client = useApolloClient();

    const [startChapter] = useMutation(START_CHAPTER_MUTATION, {
        onCompleted: ({ startChapter }) => {
            updateChapter(startChapter, client);
            setSelectedChapter(0);
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: book.id } })
    })

    if (showPrintableList) {
        return <PrintableStudentsList classId={book.studentsClass.id} school={currentUser.school} exitPrintMode={() => setShowPrintableList(false)} />
    }

    return (
        <div className={styles.settings}>
            {book.bookTemplate.isWriteToRaise ? (
                <WriteToRaiseBookSettings
                    setShowPrintableList={setShowPrintableList}
                    startChapter={startChapter}
                    setSelectedChapter={setSelectedChapter}
                    book={book}
                />
            ) : (
                <>
                    <div className={styles.settingsContent}>
                        <div className='card card-mobile'>
                            <form className='u-m-top-2 u-m-base-2' onSubmit={handleSubmit(onSubmit)}>
                                <div className='basic-form__group'>
                                    <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='title'>Book Title</label>
                                    <input
                                        name='title'
                                        defaultValue={book.title}
                                        disabled={!book.bookTemplate.editableTitle}
                                        placeholder='Book Title (required)'
                                        className={cx('basic-form__text-box', styles.settingsTitleInput)}
                                        ref={register}
                                        type='text'
                                    />
                                </div>
                                {errors.title && <p className='basic-form__hint'>{errors.title.message}</p>}
                                {/*
                        <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='visibleContent'>Foreword</label>
                        <p className='u-m-base-2'>Printed in the published book{book.status <= BOOK_STATUS.completed && ' (can be edited before publishing)'}</p>
                         <div className={styles.editorHolder}>
                            {
                                book.status <= BOOK_STATUS.completed ? (
                                    <Fragment>
                                        <EditorJs
                                            data={bookForeword}
                                            placeholder='Book foreword (required)'
                                            instanceRef={instance => (editorJsRef.current = instance)}
                                            enableReInitialize={false}
                                            minHeight='0'
                                            onChange={onEditorChange}
                                            logLevel='WARN'
                                        />
                                        <input type='hidden' name='foreword' ref={register} defaultValue={JSON.stringify(bookForeword)} />
                                        {errors.foreword && <p className='basic-form__hint'>{errors.foreword.message}</p>}
                                    </Fragment>
                                ) : (
                                    <Output data={bookForeword} />
                                )
                            }
                        </div> */}
                                {(book.isSingleChapter && book.chapters[0].status > CHAPTER_STATUS.writing_ready) &&
                                    <div className='basic-form__group u-m-top-3'>
                                        <label className='basic-form__text-label basic-form__text-label--auto heavy'>Enable StarCasting</label>
                                        <input disabled={book && book.chapters[0].status >= CHAPTER_STATUS.voting} className={`switch ${book && book.chapters[0].status >= CHAPTER_STATUS.voting && 'switchDisabled'}`} type='checkbox' id='switch' checked={scEnabled} onChange={(e) => setScEnabled(!scEnabled)} value={scEnabled} />
                                        <label className={`switchLabel ${book && book.chapters[0].status >= CHAPTER_STATUS.voting && 'switchLabelDisabled'}`} htmlFor='switch'>Enable StarCasting</label>
                                        {<AlertCircle className='u-link u-m-left-2' onClick={() => setShowScModal(true)} />}
                                    </div>
                                }

                                {
                                    book.status <= BOOK_STATUS.completed && (book.bookTemplate.editableTitle || book.isSingleChapter && book.chapters[0].status > CHAPTER_STATUS.writing_ready && book.chapters[0].status < CHAPTER_STATUS.voting) && (
                                        <Fragment>
                                            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                            <Button disabled={savingSettings || !watchTitle} type='submit' className='u-m-right-2'>
                                                {savingSettings ? 'Saving...' : 'Save Changes'}
                                            </Button>
                                        </Fragment>
                                    )
                                }
                            </form>
                            {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} />}
                            <p>
                                Print out the resources: a list of student login codes and letters to parents (detailing how to purchase books).
                                    {book.status === BOOK_STATUS.new &&  'Finally when you are ready, hit setup writing.'}
                                </p>
                            <ResourcesList showTodo={true} book={book} setShowPrintableList={setShowPrintableList} />
                        </div>
                    </div>
                    <div className={styles.sidebar}>
                        <div className="u-text-center">
                            {book.isSingleChapter && book.status === BOOK_STATUS.new && book.classPack.isPromo && (
                                <button
                                    className='btn-reset heavy u-display-flex u-align-center'
                                    style={{
                                        borderRadius: '5px',
                                        backgroundColor: '#C33764',
                                        padding: '5px 10px',
                                        width: '100%',
                                        color: '#fff',
                                    }}
                                    onClick={() => setShowAboutPromo(true)}
                                >
                                    <AlertCircle size='16' className='u-m-right-1' />Help &amp; Guides
                                </button>
                            )}
                        </div>
                        <div className={styles.sidebarContent}>
                            <div className={styles.sidebarActions}>
                                {
                                    book.isSingleChapter && book.chapters[0].status === CHAPTER_STATUS.new ? (
                                        <Button disabled={!book.title} onClick={() => startChapter({ variables: { bookChapterId: book.chapters[0].id } })}>Setup Writing</Button>
                                    ) :
                                        book.chapters[1] && book.chapters[1].status === CHAPTER_STATUS.new ?
                                            <Button disabled={!book.title} onClick={() => setSelectedChapter(0)}>Setup Chapter 1</Button> :
                                            <Button outline onClick={() => setSelectedChapter(book.status <= BOOK_STATUS.completed ? book.currentChapter : -1)}>Continue</Button>
                                }
                            </div>
                            <ResourcesList book={book} setShowPrintableList={setShowPrintableList} />
                        </div>
                    </div>
                </>
            )}
            {
                showScModal && (
                    <Modal closeModal={() => setShowScModal(false)}>
                        <p className="u-m-base-2">Starcasting is the name we give to our peer review system. You have full control over which entries will be included (pupils will not know if you chose to exclude their work).</p>
                        <p>Pupils are shown a single anonymised entry at a time and asked to give the piece a star rating based on how well they liked it. Pupils can review multiple pieces. The class winner is the entry with the highest average star rating.</p>
                    </Modal>
                )
            }
        </div>
    )
}

export default BookSettings;
