import React from 'react';
import ActionCable from 'actioncable';
import {
    ActionCableConsumer,
    ActionCableProvider,
} from '@thrash-industries/react-actioncable-provider';

const PromoActionCable = ({ setPromoAssignDone, requestId }) => {
    const hostname = window && window.location && window.location.hostname;

    const getCableEndpoint = () => {
        if (hostname !== "localhost" && !hostname.includes("staging") && hostname.includes('boom')) {
            return 'wss://cable.boomhub.app/cable'
        }
        if (hostname !== 'localhost' && !hostname.includes('staging')) {
            return 'wss://cable.goapps.app/cable'
        }
        if (hostname === "localhost") {
            return "wss://goportal-cable.herokuapp.com/cable";
        }
        return "wss://goapps-staging-cable.herokuapp.com/cable";
    }
    const cable = ActionCable.createConsumer(getCableEndpoint());

    return (
        <ActionCableProvider cable={cable}>
            <ActionCableConsumer
                channel={{
                    channel: "MultiplePurchasesNotificationsChannel",
                    id: `${requestId}`
                }}
                onReceived={(message) => {
                    setPromoAssignDone(message);
                }}
            ></ActionCableConsumer>
        </ActionCableProvider>
    );
};

export default PromoActionCable;

