// utils for books
import { 
    GET_CLASS_BOOK,
    GET_STUDENT_ENTRY 
} from './graphql';

export const updateChapter = (data, client) => {
    if (data.errors && data.errors.length !== 0) {
        alert(data.errors[0]);
        return;
    } else {
        const { bookChapter: chapter } = data;
        const bookQuery = client.readQuery({ query: GET_CLASS_BOOK, variables: { classBookId: chapter.classBook.id } });
        if (bookQuery) {
            const newChapters = [];
            bookQuery.classBook.chapters.forEach(c => {
                newChapters.push(c.id === chapter.id ? chapter : c);
            })
            client.writeQuery({
                query: GET_CLASS_BOOK,
                variables: {
                    classBookId: chapter.classBook.id
                },
                data: {
                    classBook: {
                        ...bookQuery.classBook,
                        chapters: newChapters
                    }
                }
            });
        }
    }
}

export const updateEntry = (data, client) => {
    if (data.errors && data.errors.length !== 0) {
        alert(data.errors[0]);
        return;
    } else {
        client.writeQuery({
            query: GET_STUDENT_ENTRY,
            variables: {
                id: data.bookChapterStudentEntry.id
            },
            data: {
                studentEntry: data.bookChapterStudentEntry
            }
        })
    }
}


// book orders
export const getDefaultOrderDetails = students => {
    const orderDetails = [{ id: 'generic_copies', name: 'Class copies', copies: 1 }]
    students.forEach(student => (
        orderDetails.push({
            id: student.id,
            name: student.name,
            copies: 1 
        })
    ))
    return orderDetails
}

export const setOrderDetailsJson = (data) => {
    let orderDetails = {};
    data.forEach((_, key) => orderDetails[data[key].id] = data[key].copies);
    return JSON.stringify(orderDetails);
}
