import React, { useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ArrowLeft } from "react-feather";
import { BOOK_ORDER_TYPE } from "@axeedge/go-shared-utils";
import { GET_CLASS_QUERY } from "../../../../../../services/class/graphql";
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { ADD_PRINT_BOOK_ORDER, GET_CLASS_BOOK } from "../../../../services/graphql";
import { AuthContext } from "../../../../../../services/auth/AuthProvider";
import { setOrderDetailsJson } from "../../../../services/utils";
import styles from '../../components/EditOrder/EditOrder.module.scss';
import cx from 'classnames';

const getExtraOrderDetails = students => {
    const orderDetails = []
    students.forEach(student => (
        orderDetails.push({
            id: student.id,
            name: student.name,
            copies: 0
        })
    ))
    return orderDetails
}

const WtrAdditionalCopies = ({ close, book }) => {

    const [orderCopies, setOrderCopies] = useState();
    const [formErrors, setFormErrors] = useState([]);

    const { currentUser } = useContext(AuthContext);

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: book.studentsClass.id
        }
    });

    useEffect(() => {
        if (data && data.studentsClass) {
            setOrderCopies(getExtraOrderDetails(data.studentsClass.students))
        }
    }, [data]);

    const [addPrintBookOrder, { loading: addingOrder }] = useMutation(ADD_PRINT_BOOK_ORDER, {
        onCompleted: ({ addPrintBookOrder }) => {
            if (addPrintBookOrder.errors && addPrintBookOrder.errors.length !== 0) {
                setFormErrors(addPrintBookOrder.errors);
                return;
            }
            if (addPrintBookOrder?.printBookOrder?.id) {
                close()
            }
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: book.id } })
    });


    const onUpdateCopies = (value, id) => {
        const newCopies = orderCopies.map(el => el.id === id ? { ...el, copies: +value } : el);
        setOrderCopies(newCopies)
    }

    const getTotals = copies => {
        return copies.reduce((acc, curr) => acc + curr.copies, 0) * 3
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass && orderCopies) {

        return (
            <>
                <h1 className='icon-title'>
                    <button onClick={close} className='btn-reset u-p-top-1 u-m-right-1'><ArrowLeft /></button>
                    Additional Copies
                </h1>
                <div className="row">
                    <div className="col-md-6 u-m-base-3">
                        <div className={styles.wtrCopiesHeader}>
                            {/* <p className={styles.wtrCopiesHeaderParent}>Parent orders</p> */}
                            <p className={styles.wtrCopiesHeaderExtra}>Additional</p>
                        </div>
                        <ul className='u-m-base-3 list-group'>
                            {orderCopies.map(order => (
                                <li key={`order-${order.id}`} className={cx('list-item', styles.orderListAction)}>
                                    {order.name}
                                    <div className={styles.orderListAction}>
                                        {/* <span className={styles.orderListActionExtra}>xx</span> */}
                                        <input
                                            type="number"
                                            defaultValue={order.copies}
                                            onKeyDown={(e) => e.preventDefault()}
                                            min='1'
                                            onChange={(e) => onUpdateCopies(e.target.value, order.id)}
                                            className={cx('basic-form__text-box', styles.orderListActionInput)}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={styles.wtrTotalsFooter}>
                            <p>Total cost:</p>
                            <p>£{getTotals(orderCopies)}</p>
                        </div>
                        <p className="u-m-base-2">We'll add these additional orders to a single invoice that will be sent to your school. Our accounts team will be in touch to confirm before the books are printed.</p>
                        {formErrors && <FormErrors errors={formErrors} />}
                        <Button
                            onClick={() => addPrintBookOrder({
                                variables: {
                                    classBookId: book.id,
                                    orderType: BOOK_ORDER_TYPE.class,
                                    orderDetails: setOrderDetailsJson(orderCopies),
                                    shippingToStreet: currentUser?.school?.address,
                                    shippingToAddress2: currentUser?.school?.address2,
                                    shippingToCity: currentUser?.school?.city,
                                    shippingToPostcode: currentUser?.school?.zip,
                                    isAdditional: true
                                }
                            })}
                            disabled={addingOrder || getTotals(orderCopies) === 0}
                        >{addingOrder ? 'Saving...' : 'Confirm additional purchase'}</Button>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default WtrAdditionalCopies;