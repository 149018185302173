import React, { Fragment, useState } from 'react';
import Output from '../../../../../../../components/EditorJsOutput';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import styles from '../ManageVersions.module.scss';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { RESTORE_ENTRY_VERSION_MUTATION, GET_STUDENT_ENTRY } from '../../../../../services/graphql';

const History = ({ history, entryId, isCurrentVersion, date, close, setRestoreDone }) => {

    const client = useApolloClient();

    const [formErrors, setFormErrors] = useState([]);
    
    const [restoreEntry, { loading }] = useMutation(RESTORE_ENTRY_VERSION_MUTATION, {
        onCompleted: ({ restoreEntry }) => {
            if (restoreEntry.errors && restoreEntry.errors.length !== 0) {
                setFormErrors(restoreEntry.errors);
                return;
            } else {
                close();
                setRestoreDone(true);
                client.writeQuery({
                    query: GET_STUDENT_ENTRY,
                    variables: {
                        id: restoreEntry.bookChapterStudentEntry.id
                    },
                    data: {
                        studentEntry: restoreEntry.bookChapterStudentEntry
                    }
                });
            }
        }
    })

    return(
        <Fragment>
            <div className={styles.entryAreaHeader}>
                <h4 className='heavy'>{isCurrentVersion ? 'Current Version' : date}</h4>
                <Button
                    disabled={isCurrentVersion}
                    className='btn-small'
                    outline
                    onClick={() => {
                        restoreEntry({
                            variables: {
                                bookChapterStudentEntryId: entryId,
                                studentEntryHistoryId: history.id
                            }
                        })
                    }}
                >{loading ? 'Restoring...' : 'Restore this version'}</Button>
            </div>
            {formErrors.length > 0 && <FormErrors errors={formErrors} />}
            <Output data={JSON.parse(history.text)} />
        </Fragment>
    )
}

export default History;