import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import { CURRENT_PROMO_QUERY } from '../Class/scenes/PromoBook/services/graphql';
import PromoContent from './PromoContent';

const Promo = ({ promoId }) => {

    const { data, loading, error } = useQuery(CURRENT_PROMO_QUERY);

    if (loading) return <Loader />;
    if (error) return <div>{error.message}</div>;

    if (data && data.currentPromos) {
        const currentPromo = data.currentPromos.find(promo => promo.id === promoId);
        return (
            <>
                {currentPromo && <PromoContent currentPromo={currentPromo} />}
            </>
        )
    }
    return null
}
export default Promo;