
import React, { useState } from "react";
import PromoActionCable from "../PromoActionCable";
import loader from '../../../images/loader.svg';
import { useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import { Link } from '@reach/router';
import { GET_CLASS_PACKS } from '../services/graphql';
import { GET_CLASS_QUERY, } from "../../../services/class/graphql";

const PurchaseDone = ({ currentPromo, requestId }) => {

    const client = useApolloClient();


    const [assignDone, setAssignDone] = useState(false);
    const [assignError, setAssignError] = useState(false);

    const [getClassPacks] = useLazyQuery(GET_CLASS_PACKS, {
        onCompleted: ({ classPacks }) => {
            classPacks.forEach(pack => {
                const clsQuery = client.readQuery({ query: GET_CLASS_QUERY, variables: { id: pack.studentsClass.id } });
                if (clsQuery) {
                    const { studentsClass, ...newPack } = pack;
                    client.writeQuery({
                        query: GET_CLASS_QUERY,
                        variables: { id: pack.studentsClass.id },
                        data: {
                            studentsClass: {
                                ...clsQuery.studentsClass,
                                classPacks: [
                                    ...clsQuery.studentsClass.classPacks,
                                    newPack
                                ]
                            }
                        }
                    });
                }
            });
            setAssignDone(true);
        }
    })


    const getNewPacks = data => {
        if (data.status === 1 && data.classPacks.length > 0) {
            getClassPacks({ variables: { classPackIds: [...data.classPacks] } });
        } else if (data.status === 2) {
            setAssignError(true);
        }
    }

    return (
        <>
            <PromoActionCable requestId={requestId} setPromoAssignDone={getNewPacks} />
            <div className='row'>
                <div className="col-md-8">
                    {assignDone ? (
                        <>
                            <h3 className='heavy'>All done!</h3>
                            <h3 className='heavy'>Your books have been created and assigned to the selected classes.</h3>
                            <Link to='/' className='button u-m-base-2'>Start books</Link>
                            {currentPromo.resourcesUrl && (
                                <div className='panelLight u-m-base-2'>
                                    <h3 className='heavy'>Download &amp; Print Guides</h3>
                                    <p className='u-m-base-2'>Please take a few moments to download and share our handy project guide to your staff.</p>
                                    <a className='button buttonOutline u-m-base-1' href={currentPromo.resourcesUrl} download>
                                        Download  Teacher resources
                                    </a>
                                </div>
                            )}
                            <div className='panelLight'>
                                <h3 className='heavy '>Book a Training Session</h3>
                                <p className='u-m-base-2'>Why not book your staff onto a free webinars to learn more about this book project and how to get the best from it.</p>
                                <a className='button buttonOutline u-m-base-1' href='mailto:schools@goapps.app?subject=Jubilee Training Session'>
                                    Book training
                                </a>
                            </div>
                        </>
                    ) : (
                        <>
                            <h3>Please wait, assigning books...</h3>
                            <img src={loader} alt='loading' />
                        </>
                    )}

                    {assignError && <p>Ooops, there was an error assigning the books</p>}
                </div>
            </div>
        </>
    )
}

export default PurchaseDone;