import React from 'react';
import FaqItem from '../../../components/FaqItem';
import ImageFadeIn from "react-image-fade-in";
import styles from '../Promo.module.scss';
import LeaversAd from '../../../images/leavers-2023.png';

const AboutBookContentYearEnd = ({ book }) => {

    return (
        <>
            <h2>Class of 2023 - A Treasured Keepsake</h2>
            <div className="u-m-top-3 u-m-base-3">
                <ImageFadeIn src={LeaversAd} alt="Class of 2023" />
            </div>

            <div className={styles.about}>
                <div className={styles.aboutContent}>
                    <p className='u-m-base-2'>A whole class writing project that allows pupils to use their writing skills as they collaborate with their peers. </p>
                    <p className='u-m-base-2'>This unique leavers book will raise self esteem and positively build confidence as your pupils transition to their new schools.</p>
                    <p className='heavy u-m-base-1'><a target='blank' href="https://boomwriter.co.uk/leavers" rel='noopener noreferrer' className='link-underline u-text-dark'>More about this project</a></p>
                    <p className='u-m-base-2 u-m-top-2'>Books cost just £6.99 per pupil.</p>
                    <p><b>Please allow at least 14 days from publishing to receive your books.</b></p>
                </div>
                <ImageFadeIn width='140' src={book.coverUrl} alt="..." />
            </div>

            {book.resourcesUrl &&
                <>
                    <h2 className='u-m-top-3'>Themes and resources</h2>
                    <p className='u-m-base-2'>Download our teacher resource pack that includes:</p>
                    <ul className='list-bullet'>
                        <li>Suggested themes each with supporting resources</li>
                        <li>How to guide</li>
                        <li>Parent contribution form</li>
                    </ul>
                    <a className='button u-m-base-2' href={book.resourcesUrl}>Download teacher resources</a>
                </>
            }
            <ul className='u-m-top-2 u-m-base-2'>
                <FaqItem title='How long does a book take to complete?'>
                    <p>As each child will be completing a single piece of writing to include, the book can take much time as required. The whole project could form part of a few days of writing or be completed in just a few short sessions.</p>
                </FaqItem>
                <FaqItem title='Do we have to vote?'>
                    <p className='u-m-base-1'>Not at all- the voting system is there to add some excitement to the writing process and an authentic audience for every writer but you can choose to skip voting as required. </p>
                </FaqItem>

                <FaqItem title='Can the PTA support the project and purchase the books on behalf of school?'>
                    <p>Absolutely! In that situation, we would ask the school to make the initial purchase and then work with the PTA for reimbursement. This ensures that we can get the books printed as soon as possible for schools.</p>
                </FaqItem>

                <FaqItem title='How do my pupils login?'>
                    <p className='u-m-base-1'>As you are already a GoRead school, all your children need to do is login a click GoWrite and the task will be there for them.</p>
                </FaqItem>
                {/* 
                <FaqItem title='Are there any guidance materials for using GoWrite?'>
                    <p className='u-m-base-1'>Yes - we have a simple to follow writing guide for staff but we have designed GoWrite to be as easy to follow as possible for staff. All materials can be download once the project is created.</p>
                </FaqItem> */}
            </ul>
        </>
    )
}

export default AboutBookContentYearEnd;