import { gql } from 'apollo-boost';
import { classPackQuery } from '../../../services/constants';

export const GET_SCHOOL_ADMINS = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        admins {
            id
            name
            displayName
        }
    }
}
`;

export const GET_PROMO_CLASSES = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        billingProfile {
            id
            contactPerson
            contactEmail
            contactPhone
        }
        address
        address2
        city
        classes {
            id
            name
            studentsCounter
            hasCurrentPromoBook
            academicYear
        }
    }
}
`;

export const PURCHASE_AND_ASSIGN_PROMO_TO_CLASSES = gql`
mutation purchaseAndAssignPromoPacksToClasses($studentClassesIds: [ID!]!, $schoolId: ID!) {
    purchaseAndAssignPromoPacksToClasses(studentClassesIds: $studentClassesIds, schoolId: $schoolId) {
        requestId
        errors
    }
}
`;


export const GET_CLASS_PACKS = gql`
query classPacks($classPackIds: [ID!]!) {
    classPacks(classPackIds: $classPackIds) {
        ${classPackQuery}
        studentsClass {
            id
        }
    }
}
`;

export const GET_SCHOOL_PACKS = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        purchasedPacks {
            id
            instances
            academicYear
            maxNumberOfStudents
            offerDetails
            status
            price
            purchasedAt
            packOffer {
                id
                defaultNumberOfStudentsPerInstance
                name
                instances
                price
            }
            studentsClasses {
                id
                name
            }
        }
    }
}
`;

export const ASSIGN_PURCHASED_PACK_AND_BOOK_TO_CLASS = gql`
mutation assignPurchasedPackAndBookToClass($studentsClassId: ID!, $bookTemplateId: ID!, $purchasedPackId: ID!, $packOfferIndex: Int!) {
    assignPurchasedPackAndBookToClass(studentsClassId: $studentsClassId, bookTemplateId: $bookTemplateId, purchasedPackId: $purchasedPackId, packOfferIndex: $packOfferIndex) {
        errors
        classBook {
            id
        }
    }
}
`;
