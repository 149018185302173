import React from 'react';
import { Button } from '@axeedge/go-teacher-components';
import styles from './AboutPromoBook.module.scss';
import AboutBookContentYearEnd from '../../../Promo/components/AboutBookContentYearEnd';

const AboutPromoBook = ({ closeAbout, bookData }) => {

    return (
        <>
            <div className='page-header no-print'>
                <div className='page-header__content'>
                    <h1 className='page-header__content__title'>About this book</h1>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-8">
                    <AboutBookContentYearEnd book={bookData.bookTemplate} />
                </div>
                <div className="col-md-4">
                    <div className={styles.panel}>
                        {bookData.bookTemplate.resourcesUrl && (
                            <>
                                <p className='u-m-base-2 heavy'>1. Download guide:</p>
                                <a className='button' href={bookData.bookTemplate.resourcesUrl} download>Teacher guide</a>
                                <div className={styles.panelSep}></div>
                            </>
                        )}

                        <p className='u-m-base-2 heavy'>2. Parent letters:</p>
                        <a className='button' href={`/book/${bookData.id}?printParentLetters=true`} target='_blank' rel="noopener noreferrer">Parent Letters</a>

                        <div className={styles.panelSep}></div>

                        <p className='u-m-base-2 heavy'>3. Setup your book:</p>
                        <Button onClick={closeAbout} >Setup your book</Button>

                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutPromoBook;