import { gql } from 'apollo-boost';
import { classPackQuery } from '../constants';

export const GET_CLASS_QUERY = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        students {
            id
            firstName
            lastName
            name
            token
            email
            loginCode
            expiredToken
            storeCode
        }
        classPacks {
           ${classPackQuery}
        }
    }
}
`;
