import React, { Fragment, useEffect } from 'react';
import _ from 'lodash';

import StudentsEntriesList from '../../../StudentsEntriesList';

const WritingClosed = ({ chapter, setEnabled }) => {
    useEffect(() => {
        setEnabled(_.filter(chapter.studentEntries, 'eligibleToWin').length > 1);
    }, [chapter, setEnabled])

    return (
        <Fragment>
            <h1 className='u-m-base-3'>Writing Closed</h1>
            {chapter.classBook.castingEnabled ? <p className='u-m-base-2'>Review student entries, give rewards and choose which could be winning entries.{chapter.classBook.isSingleChapter && <> <br/>(You can turn starcasting off in the book settings)</>}</p> :
            <p className="u-m-base-2">Review entries and make any edits. All entries will be included in the completed book.</p>}
            <StudentsEntriesList 
                award={true}
                eligible={chapter.classBook.castingEnabled}
                status={chapter.status}
                entries={chapter.studentEntries}
                visibleInCasting={chapter.classBook.castingEnabled}
                writing={true}
            />
        </Fragment>
    )
}

export default WritingClosed;