import { gql } from 'apollo-boost';

import {
	classBookQuery,
	chapterQuery,
	studentEntryQuery,
} from '../../../services/constants';

export const GET_CLASS_BOOK = gql`
query classBook($classBookId: ID!) {
    classBook(classBookId: $classBookId) {
        ${classBookQuery}
    }
}
`;

export const GET_CLASS_PACKS = gql`
	query classPacks($id: ID!) {
		studentsClass(id: $id) {
			id
			students {
				id
			}
			classPacks {
				id
				isPromo
				bookTemplate {
					id
				}
				bookTemplateCategory {
					id
					name
				}
				purchasedPack {
					id
					instances
				}
			}
		}
	}
`;

export const EDIT_CLASS_BOOK = gql`
mutation editClassBook($classBookId: ID!, $title: String, $foreword: String!, $castingEnabled: Boolean) {
    editClassBook(classBookId: $classBookId, title: $title, foreword: $foreword, castingEnabled: $castingEnabled) {
        classBook {
            ${classBookQuery}
        }
        errors
    }
}
`;

export const EDIT_BOOK_CHAPTER = gql`
mutation editBookChapter($bookChapterId: ID!, $title: String!, $description: String, $content: String, $guidance: String, $vocabularyWords: [String!]) {
    editBookChapter(bookChapterId: $bookChapterId, title: $title, description: $description, content: $content, guidance: $guidance, vocabularyWords: $vocabularyWords) {
        ${chapterQuery}
        errors
    }
}`;

export const START_CHAPTER_MUTATION = gql`
mutation startChapter($bookChapterId: ID!) {
    startChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const START_WRITING_MUTATION = gql`
mutation startWritingForChapter($bookChapterId: ID!) {
    startWritingForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const END_WRITING_MUTATION = gql`
mutation stopWritingForChapter($bookChapterId: ID!) {
    stopWritingForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const REOPEN_WRITING_MUTATION = gql`
mutation reopenWritingForChapter($bookChapterId: ID!, $teacherMessage: String!) {
    reopenWritingForChapter(bookChapterId: $bookChapterId, teacherMessage: $teacherMessage) {
        ${chapterQuery}
        errors
    }
}
`;

export const START_CASTING_SETUP_MUTATION = gql`
mutation startCastingSetupForChapter($bookChapterId: ID!) {
    startCastingSetupForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const UNSTART_CASTING_SETUP_MUTATION = gql`
mutation unstartCastingSetupForChapter($bookChapterId: ID!) {
    unstartCastingSetupForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const START_CASTING_MUTATION = gql`
mutation startCastingForChapter($bookChapterId: ID!) {
    startCastingForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const STOP_CASTING_MUTATION = gql`
mutation stopCastingForChapter($bookChapterId: ID!) {
    stopCastingForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const REOPEN_CASTING_MUTATION = gql`
mutation reopenCastingForChapter($bookChapterId: ID!) {
    reopenCastingForChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const FINISH_CHAPTER_MUTATION = gql`
mutation finishChapter($bookChapterId: ID!) {
    finishChapter(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        errors
    }
}
`;

export const AUTO_SET_WINNER_MUTATION = gql`
mutation setChapterWinner($bookChapterId: ID!) {
    setChapterWinner(bookChapterId: $bookChapterId) {
        ${chapterQuery}
        tieBreaks {
            ${studentEntryQuery}
        }
        errors
    }
}`;

export const CHOOSE_WINNER_MUTATION = gql`
mutation chooseChapterWinner($bookChapterId: ID!, $bookChapterStudentEntryId: ID!) {
    chooseChapterWinner(bookChapterId: $bookChapterId, bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        ${chapterQuery}
        errors
    }
}
`;

export const LEAVE_ENTRY_FEEDBACK_MUTATION = gql`
mutation feedbackForChapterEntry($bookChapterStudentEntryId: ID!, $feedback: String!, $requiresRevision: Boolean!) {
    feedbackForChapterEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, feedback: $feedback, requiresRevision: $requiresRevision) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const RESTORE_ENTRY_VERSION_MUTATION = gql`
mutation restoreEntry($bookChapterStudentEntryId: ID!, $studentEntryHistoryId: ID!) {
    restoreEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, studentEntryHistoryId: $studentEntryHistoryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const GET_STUDENT_ENTRY = gql`
query studentEntry($id: ID!) {
    studentEntry(id: $id) {
        ${studentEntryQuery}
    }
}
`;

export const GET_ENTRY_HISTORIES = gql`
query studentEntry($id: ID!) {
    studentEntry(id: $id) {
        id
        histories {
            id
            text
            createdAt
            historyType
        }
    }
}
`;

export const MARK_ENTRY_ELIGIBLE_MUTATION = gql`
mutation markChapterEntryAsEligibleToWin($bookChapterStudentEntryId: ID!) {
    markChapterEntryAsEligibleToWin(bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const UNMARK_ENTRY_ELIGIBLE_MUTATION = gql`
mutation unmarkChapterEntryAsEligibleToWin($bookChapterStudentEntryId: ID!) {
    unmarkChapterEntryAsEligibleToWin(bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const MARK_ENTRY_HIDDEN_MUTATION = gql`
mutation markChapterEntryAsHiddenToCasting($bookChapterStudentEntryId: ID!) {
    markChapterEntryAsHiddenToCasting(bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const UNMARK_ENTRY_HIDDEN_MUTATION = gql`
mutation markChapterEntryAsUnhiddenToCasting($bookChapterStudentEntryId: ID!) {
    markChapterEntryAsUnhiddenToCasting(bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const SET_TEACHER_EDIT_WINNER_MUTATION = gql`
mutation teacherEditWinnerEntry($bookChapterStudentEntryId: ID!, $teacherEdit: String!) {
    teacherEditWinnerEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, teacherEdit: $teacherEdit) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const SET_TEACHER_EDIT_MUTATION = gql`
mutation teacherEditEntry($bookChapterStudentEntryId: ID!, $teacherEdit: String!) {
    teacherEditEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, teacherEdit: $teacherEdit) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}
`;

export const SET_BOOK_COMPLETED_MUTATION = gql`
mutation completeClassBook($classBookId: ID!) {
    completeClassBook(classBookId: $classBookId) {
        classBook {
            ${classBookQuery}
        }
        errors
    }
}
`;

export const ADD_PRINT_BOOK_ORDER = gql`
mutation addPrintBookOrder($classBookId: ID!, $orderType: Int!, $orderDetails: String!, $shippingToStreet: String!, $shippingToAddress2: String, $shippingToCity: String!, $shippingToPostcode: String!, $isAdditional: Boolean) {
    addPrintBookOrder(classBookId: $classBookId, orderType: $orderType, orderDetails: $orderDetails, shippingToStreet: $shippingToStreet, shippingToAddress2: $shippingToAddress2, shippingToCity: $shippingToCity, shippingToPostcode: $shippingToPostcode, isAdditional: $isAdditional) {
        printBookOrder {
           id
           classBook {
                ${classBookQuery}
           }
           orderDetails
           orderType
           sendToDestinationAt
           sendToPrinterAt
           status
           shippingToStreet
           shippingToAddress2
           shippingToCity
           shippingToPostcode
        }
        errors
    }
}
`;

export const READY_TO_PRINT_CLASS_BOOK = gql`
mutation readyToPrintClassBook($classBookId: ID!) {
    readyToPrintClassBook(classBookId: $classBookId) {
        classBook {
            ${classBookQuery}
       }    
        errors
    }
}
`;

export const ASSIGN_PURCHASED_PACK_TO_CLASS_BOOK = gql`
mutation assignPurchasedPackToClassBook($classBookId: ID!, $purchasedPackId: ID!) {
    assignPurchasedPackToClassBook(classBookId: $classBookId, purchasedPackId: $purchasedPackId) {
        classBook {
            ${classBookQuery}
       }    
        errors
    }
}
`;
