import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { Button } from '@axeedge/go-teacher-components';

// import Tip from '../../../../../../components/Tip';
import StudentsEntriesList from '../../../StudentsEntriesList';
import StarCastingCriteria from '../StarCastingCriteria';
import {
    UNSTART_CASTING_SETUP_MUTATION,
    START_CASTING_MUTATION
} from '../../../../services/graphql';
import { updateChapter } from '../../../../services/utils';

const VotingSetup = ({ chapter }) => {
    const client = useApolloClient();

    const [goBack] = useMutation(UNSTART_CASTING_SETUP_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ unstartCastingSetupForChapter }) => {
            if (unstartCastingSetupForChapter.errors && unstartCastingSetupForChapter.errors.length !== 0) {
                alert(unstartCastingSetupForChapter.errors[0]);
                return;
            } else {
                updateChapter(unstartCastingSetupForChapter, client);
            }
        }
    })

    const [startCasting] = useMutation(START_CASTING_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ startCastingForChapter }) => {
            updateChapter(startCastingForChapter, client);
        }
    })

    return (
        <div className='chapter__voting-setup'>
            <h1 className='u-m-base-3'>Begin StarCasting</h1>
            <p className='u-m-base-3'>Pupils will be able to view and peer assess all submitted entries, however only those below will be able to win:</p>
            <StudentsEntriesList 
                className='u-m-base-4'
                onlyEligible={true}
                status={chapter.status}
                entries={chapter.studentEntries}
            />
            <StarCastingCriteria chapter={chapter} disabled />

            <div className='u-m-top-3'>
                <Button onClick={() => startCasting()} primary>Begin!</Button>
                <Button outline onClick={() => goBack()} className="u-m-left-1">Cancel Starcasting</Button>
            </div>
        </div>
        
    )
}

export default VotingSetup;
