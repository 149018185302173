import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import { Button } from '@axeedge/go-teacher-components';
import cx from 'classnames';
import { CHOOSE_BOOK_MUTATION } from '../../services/graphql';
import { GET_CLASS_QUERY } from '../../../../services/class/graphql';

import styles from './PackBook.module.scss';
import ImageFadeIn from "react-image-fade-in";

import noCover from '../../../../images/no-cover.png';

const PackBook = ({ book, classId, classPackId, bookTemplateCategoryId }) => {
    const [chooseBook, { loading }] = useMutation(CHOOSE_BOOK_MUTATION, {
        variables: {
            classPackId,
            bookTemplateId: book.id,
            bookTemplateCategoryId,
        },
        onCompleted: ({ assignBookToClassPack }) => {
            if (assignBookToClassPack.errors && assignBookToClassPack.errors.length !== 0) {
                alert(assignBookToClassPack.errors[0]);
                return;
            } else if (assignBookToClassPack.classBook.id) {
                navigate(`/book/${assignBookToClassPack.classBook.id}`);
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId } }]
    });

    return (
        <div className='col-md-6 u-m-base-2'>
            <div className='card'>
                <div className={cx('card-body', styles.book)}>
                    <div className={styles.bookMain}>
                        <h2 className='u-m-base-1'>{book.name}</h2>
                        <p className='small u-m-base-2'>{book.description}</p>
                        <div className='u-m-base-2'>
                            <Button onClick={() => chooseBook()} primary>{loading ? 'Loading...' : 'Select'}</Button>
                        </div>
                        {
                            book.previewUrl && (
                                <a className='button buttonOutline u-m-right-1 u-m-base-1' href={book.previewUrl} download>
                                    Preview
                                </a>
                            )
                        }
                        {
                            book.resourcesUrl && (
                                <a className='button buttonOutline u-m-base-1' href={book.resourcesUrl} download>
                                    Teacher resources
                                </a>
                            )
                        }
                    </div>
                    <ImageFadeIn className={cx(styles.bookImg, {[styles.bookImgShadow]: book.coverUrl})} src={book.coverUrl || noCover} alt='' />
                </div>
            </div>
        </div>
    )
}

export default PackBook;
