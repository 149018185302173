import { gql } from 'apollo-boost';

import { chapterQuery } from '../../../services/constants';

export const GET_TASK_QUERY = gql`
query classBook($classBookId: ID!) {
    classBook(classBookId: $classBookId) {
        id
        title
        castingEnabled
        foreword
        status
        startsAt
        endsAt
        chapters {
            id
            status
            vocabularyWords
        }
        bookTemplate {
            id
            description
            resources {
                id
                name
                url
            }
        }
        studentsClass {
            id
        }
    }
}
`;

export const ADD_TASK_MUTATION = gql`
mutation addWritingActivity($studentsClassId: ID!, $title: String!, $description: String!, $resources: [BookTemplateResourceInput!], $castingEnabled: Boolean!, $vocabularyWords: [String!]) {
    addWritingActivity(studentsClassId: $studentsClassId, title: $title, description: $description, resources: $resources, castingEnabled: $castingEnabled, vocabularyWords: $vocabularyWords) {
        errors
        classBook {
            id
        }
    }
}
`;

export const EDIT_TASK_MUTATION = gql`
mutation editWritingActivity($classBookId: ID!, $studentsClassId: ID!, $title: String!, $description: String!, $resources: [BookTemplateResourceInput!], $castingEnabled: Boolean!, $vocabularyWords: [String!]) {
    editWritingActivity(classBookId: $classBookId, studentsClassId: $studentsClassId, title: $title, description: $description, resources: $resources, castingEnabled: $castingEnabled, vocabularyWords: $vocabularyWords) {
        errors
        classBook {
            id
        }
    }
}
`;

export const DELETE_TASK_MUTATION = gql`
mutation deleteClassBook($classBookId: ID!) {
    deleteClassBook(classBookId: $classBookId) {
        errors
        deleted
    }
}
`;

export const GET_S3_UPLOAD_QUERY = gql`
query s3UploadUrlFor($name: String, $fileType: String, $object: String!) {
    s3UploadUrlFor(name: $name, fileType: $fileType, object: $object) {
        name
        uploadUrl
    }
}
`;

export const START_WRITING_ACTIVITY_MUTATION = gql`
mutation startWritingActivity($classBookId: ID!, $startsAt: String, $endsAt: String) {
    startWritingActivity(classBookId: $classBookId, startsAt: $startsAt, endsAt: $endsAt) {
        ${chapterQuery}
        errors
    }
}
`;