import React from "react";
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { CheckCircle, Download, ArrowRight } from 'react-feather';
import resourceImg from '../../../../images/book/resources.png';
import beeResourceImg from '../../../../images/book/bee.png';
import loginImg from '../../../../images/book/login.png';
import lettersImg from '../../../../images/book/letters.png';
import ImageFadeIn from 'react-image-fade-in';
import styles from './ResourcesList.module.scss';
import cx from 'classnames';

const ResourcesList = ({ book, showTodo = false, setShowPrintableList }) => {

    return (
        <>
            {showTodo ? (
                <ul className={cx(styles.todoList, 'u-m-top-3')}>
                    <li>
                        <button className={cx('btn-reset')} onClick={() => setShowPrintableList(true)}>
                            <CheckCircle /> Download Pupil Logins
                        </button>
                    </li>

                    {book.bookTemplate.resourcesUrl && (
                        <li>
                            <a className='link-reverse' href={book.bookTemplate.resourcesUrl} download>
                                <CheckCircle />
                                <p>{book.bookTemplate.isBeeTemplate ? "Writing Bee Downloads" : "Download Teacher Resources"}</p>
                            </a>
                        </li>
                    )}

                    {((book.status > BOOK_STATUS.new && !book.writingActivity) || book.bookTemplate?.isWriteToRaise) && (
                        <li>
                            <a className='link-reverse' href={`/book/${book.id}?printParentLetters=true`} target='_blank' rel="noopener noreferrer">
                                <CheckCircle />
                                <p>Download Parent Letters</p>
                            </a>
                        </li>
                    )}
                </ul>
            ) : (
                <>
                    <h2 className='u-m-base-3 u-text-left'>Resources</h2>
                    <ul className={styles.resources}>
                        {book.bookTemplate.resourcesUrl && (
                            <li>
                                <a className={styles.resourcesLink} href={book.bookTemplate.resourcesUrl} download>

                                    {book.bookTemplate.isBeeTemplate ? (
                                        <>
                                            <div className={styles.resourcesImg}><ImageFadeIn width={48} src={beeResourceImg} alt='Resources' /></div>
                                            <p>Writing Bee Downloads</p>
                                        </>
                                    ) : <>
                                        <div className={styles.resourcesImg}><ImageFadeIn src={resourceImg} alt='Resources' /></div>
                                        <p>Teacher Resources</p>
                                    </>}
                                    <ArrowRight />
                                </a>
                            </li>
                        )}
                            {((book.status > BOOK_STATUS.new && !book.writingActivity) || book.bookTemplate?.isWriteToRaise) && (
                            <li>
                                <a className={styles.resourcesLink} href={`/book/${book.id}?printParentLetters=true`} target='_blank' rel="noopener noreferrer">
                                    <div className={styles.resourcesImg}><ImageFadeIn src={lettersImg} alt='parent letters' /></div>
                                    <p>Parent Letters</p>
                                    <Download />
                                </a>
                            </li>
                        )}
                        <li>
                            <button className={cx('btn-reset', styles.resourcesLink)} onClick={() => setShowPrintableList(true)}>
                                <div className={styles.resourcesImg}><ImageFadeIn src={loginImg} alt='parent letters' /></div>
                                <p>Pupils Logins</p>
                                <Download />
                            </button>
                        </li>
                    </ul>
                </>
            )}
        </>
    )
}

export default ResourcesList