import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import { Book } from 'react-feather';
import { Loader } from '@axeedge/go-teacher-components';

import PackBook from './components/PackBook';
import SelectBookType from './components/SelectBookType';
import { GET_CLASS_PACK } from './services/graphql';

const Pack = ({ classPackId }) => {
    const { data, error, loading } = useQuery(GET_CLASS_PACK, {
        variables: {
            classPackId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data.classPack) {
        const { book, studentsClass, bookTemplateCategory } = data.classPack;
        if (book && book.id) {
            navigate(`/book/${book.id}`);
        }

        return (
            <Fragment>
                {
                    bookTemplateCategory ? (
                        <Fragment>
                            <div className='page-header'>
                                <div className='page-header__content'>
                                    <div className='page-header__content__inner'>
                                        <h1 className='page-header__content__title'><Book /> {bookTemplateCategory.name}</h1>
                                        <p className='page-header__content__subtitle'>{bookTemplateCategory.description}</p>
                                        <p className='u-m-top-1'>Choose your {bookTemplateCategory.name} book project from the options below.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {
                                    bookTemplateCategory.bookTemplates.map(book => {
                                        return <PackBook key={`book-${book.id}`} book={book} bookTemplateCategoryId={bookTemplateCategory.id} classId={studentsClass.id} classPackId={classPackId} />
                                    })
                                }
                                </div>
                        </Fragment>
                    ) : (
                        <SelectBookType classId={studentsClass.id} classPackId={classPackId} />
                    )
                }

            </Fragment>
        )
    }




}

export default Pack;
