
import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Loader } from '@axeedge/go-teacher-components';

import { useQuery } from '@apollo/react-hooks';
import { GET_SCHOOL_ADMINS } from '../services/graphql';

const NoAdminRights = ({ schoolId, onBack }) => {

    const { data, error, loading } = useQuery(GET_SCHOOL_ADMINS, {
        variables: {
            schoolId
        }
    });

    if (loading) return <Loader />;

    if (error) return <div>{error.message}</div>;

    if (data && data.school) {

        const { school: { admins } } = data;

        return (
            <>

                <div className="page-header">
                    <div className="page-header__content">
                        <h2 className="page-header__content__title">
                            <button onClick={onBack} className='btn-reset u-m-right-2'><ArrowLeft /></button>
                            Purchase books</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-8">
                        <h3 className='heavy'>Sorry, you need administrator rights to be able to purchase Book Packs</h3>
                        <p className='u-m-base-2'>Please contact the following to either ask for Administrator rights or to make the purchase on your behalf.</p>
                        {
                            admins.map(admin => (
                                <p className='lead heavy u-m-top-1' key={`admin-${admin.id}`}>{admin.name} ({admin.displayName})</p>
                            ))
                        }
                    </div>
                </div>
            </>

        )
    }
    return null
}

export default NoAdminRights;