import React, { Fragment, useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import { AlertTriangle, Edit3, FileText } from 'react-feather';

import { FormErrors, Button } from '@axeedge/go-teacher-components';
import { CHAPTER_STATUS, ENTRY_STATUS, BOOK_STATUS } from '@axeedge/go-shared-utils';

import Output from '../../../../../../components/EditorJsOutput';
import GemButton from '../../../../../../components/GemButton';
import SaveConfirm from '../../../../../../components/SaveConfirm';

import TeacherEdit from '../TeacherEdit';
import ManageVersions from '../ManageVersions';
import VocabModule from '../../../Chapter/components/VocabModule';
import {
    GET_STUDENT_ENTRY,
    LEAVE_ENTRY_FEEDBACK_MUTATION
} from '../../../../services/graphql'

import styles from './EntryModal.module.scss'

const EntryModal = ({ chapterStatus, entry, toggleEligible, bookStatus }) => {
    const [formErrors, setFormErrors] = useState([]);
    const [newFeedback, setNewFeedback] = useState(entry.feedback || '');
    const [feedbackSaved, setFeedbackSaved] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [manageVersions, setManageVersions] = useState(false);
    const [restoreDone, setRestoreDone] = useState(false);



    const schema = Yup.object().shape({
        feedback: Yup.string()
            .required('Please enter some text')
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema
    });

    useEffect(() => {
        setValue('feedback', newFeedback);
        if (feedbackSaved && newFeedback !== entry.feedback) {
            setFeedbackSaved(false);
        }
    }, [entry, feedbackSaved, newFeedback, setFeedbackSaved, setValue]);

    const client = useApolloClient();

    const [leaveFeedback, { loading: leavingFeedback }] = useMutation(LEAVE_ENTRY_FEEDBACK_MUTATION, {
        onCompleted: ({ feedbackForChapterEntry }) => {
            if (feedbackForChapterEntry.errors && feedbackForChapterEntry.errors.length !== 0) {
                setFormErrors(feedbackForChapterEntry.errors);
                return;
            } else {
                setFeedbackSaved(true);
                client.writeQuery({
                    query: GET_STUDENT_ENTRY,
                    variables: {
                        id: feedbackForChapterEntry.bookChapterStudentEntry.id
                    },
                    data: {
                        studentEntry: feedbackForChapterEntry.bookChapterStudentEntry
                    }
                })
            }
        }
    })

    const onSubmit = values => {
        const { feedback } = values;
        leaveFeedback({
            variables: {
                bookChapterStudentEntryId: entry.id,
                feedback,
                requiresRevision: true
            }
        });
    }

    const escapeVocab = (vocab) => vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const highlightVocab = (text, vocab) => {
        let newText = text;
        vocab.map(v => {
            let exp = RegExp('\\b(' + escapeVocab(v) + ')\\b', 'gi');
            newText = newText.replace(exp, `<span class=\"tagvocab\">${v}</span>`)

        })
        return newText;
    }

    const highlightEntry = (entryData) => {
        const vocab = entry.bookChapter.vocabularyWords;
        entryData.blocks.forEach(block => {
            if (block.data.text && block.data.text.length !== 0) {
                block.data.text = highlightVocab(block.data.text, vocab)
            }
        });
        return entryData
    }



    return (
        <Fragment>
            {manageVersions ? (
                <ManageVersions entry={entry} setRestoreDone={setRestoreDone} close={() => setManageVersions(false)} />
            ) : (
                <Fragment>
                    <h1 className='u-m-base-1'>{entry.author.name} <span className={styles.loginCode}>(Login Code: <strong>{entry.author.loginCode})</strong></span></h1>
                    {!entry.isActive && <p className={styles.chapStatus}><AlertTriangle size="16" className={styles.statusIcon} />  Removed from this class</p>}
                    {entry.text ? entry.status === ENTRY_STATUS.submitted ? <p className={styles.chapStatus}><FileText size="16" className={styles.statusIcon} /> Entry has been submitted</p> : entry.isActive && <p className={styles.chapStatus}><Edit3 size="16" className={styles.statusIcon} /> Pupil is writing</p> : null}
                    <div className={styles.entryContainer}>
                        {
                            entry.text ? (
                                <Fragment>
                                    <div className={styles.entryArea}>
                                        {showEdit ? (
                                            <TeacherEdit setShowEdit={setShowEdit} bookStatus={bookStatus} entry={entry} />
                                        ) : (
                                            <>
                                                {entry.bookChapter.vocabularyWords?.length > 0 && (
                                                    <VocabModule entry={JSON.parse(entry.text)} vocab={entry.bookChapter.vocabularyWords} />
                                                )}
                                                <div className='card-entry'>
                                                    {entry.isActive && chapterStatus === CHAPTER_STATUS.writing && (
                                                        <div className={styles.restoreOpts}>
                                                            {restoreDone && <SaveConfirm message='Version restored!' hideConfirm={() => setRestoreDone(false)} />}
                                                            <Button className='btn-small u-ml-auto' outline onClick={() => setManageVersions(true)}>Manage versions</Button>
                                                        </div>
                                                    )}
                                                    {entry.bookChapter.vocabularyWords?.length > 0 ?
                                                        <Output data={highlightEntry(JSON.parse(entry.text))} /> :
                                                        <Output data={JSON.parse(entry.text)} />
                                                    }
                                                </div>
                                            </>
                                        )}
                                        {chapterStatus === CHAPTER_STATUS.completed && !showEdit && <Button className='u-m-top-2' outline onClick={() => setShowEdit(true)}>{bookStatus >= BOOK_STATUS.completed ? 'See' : 'Edit'} published version</Button>}
                                        {bookStatus >= BOOK_STATUS.completed && showEdit && <Button outline className='u-m-top-2' onClick={() => setShowEdit(false)}>Back</Button>}
                                    </div>
                                    {entry.isActive && (
                                        <div className={styles.entrySidebar}>
                                            <GemButton studentId={entry.author.id} fullWidth />
                                            {
                                                chapterStatus < CHAPTER_STATUS.voting ? (
                                                    <div className='u-m-top-2'>
                                                        <h2>Your Feedback</h2>
                                                        {
                                                            entry.text ? (
                                                                <form className='u-m-top-2' onSubmit={handleSubmit(onSubmit)}>
                                                                    <div className='basic-form__group'>
                                                                        <TextareaAutosize
                                                                            name='visibleFeedback'
                                                                            onChange={e => setNewFeedback(e.target.value)}
                                                                            value={newFeedback}
                                                                            placeholder={`Add some helpful feedback for ${entry.author.name}`}
                                                                            className='basic-form__text-box'
                                                                        />
                                                                        <input type='hidden' name='feedback' ref={register} defaultValue={newFeedback} />
                                                                    </div>
                                                                    {errors.feedback && <p className='basic-form__hint'>{errors.feedback.message}</p>}
                                                                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                                                    {feedbackSaved && <p className='u-m-base-1'>Your feedback has been saved!</p>}
                                                                    <Button disabled={newFeedback === entry.feedback} type='submit' fullWidth>{leavingFeedback ? 'Saving...' : 'Leave Feedback'}</Button>
                                                                </form>
                                                            ) : (
                                                                <p>Once {entry.author.name} has submitted their entry you can leave some useful feedback for them.</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : entry.feedback ? (
                                                    <div className='u-m-top-2'>
                                                        <h2>Your Feedback:</h2>
                                                        <p>"{entry.feedback}"</p>
                                                    </div>
                                                ) : (
                                                    <div className='u-m-top-2'>
                                                        <h2>Your Feedback:</h2>
                                                        <p>You didn't leave feedback for this entry.</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <div className='icon-title'>
                                    <AlertTriangle />
                                    <p className='u-m-left-2'>No entry submitted {chapterStatus === CHAPTER_STATUS.writing && 'yet'}</p>
                                </div>
                            )
                        }
                    </div>
                    {chapterStatus >= CHAPTER_STATUS.writing_closed && entry.text && entry.status !== ENTRY_STATUS.submitted && <p className='u-m-top-2'><AlertTriangle className='u-m-top-1' size='14' /> Fnished entry was not handed in before writing closed</p>}
                    {entry.bookChapter.classBook.castingEnabled && entry.text && chapterStatus <= CHAPTER_STATUS.writing_closed && <Button className='u-m-top-2' onClick={() => toggleEligible()}>{entry.eligibleToWin ? 'Unmark as Eligible to Win' : 'Make as Eligible to Win'}</Button>}
                </Fragment>
            )}
        </Fragment>
    )
}

export default EntryModal;
