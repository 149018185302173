import React from 'react';

import styles from './WriteToRaiseLetters.module.scss';
import cx from 'classnames';
import logo from '../../../../images/logos/boomwriter-full.svg';
import buyQrImg from '../../../../images/qr-write-buy.png';
import buyQrImgBoom from '../../../../images/qr-write-buy-boom.svg';
import wtrLogo from '../../../../images/letters/wtr-logo.png';
import brianBlessed from '../../../../images/letters/brian-blessed.png';
import bookCover from '../../../../images/letters/book-cover.png';
import bookContents from '../../../../images/letters/book-contents.png';

const WriteToRaiseLetters = ({ bookData, studentsClass }) => {

    const hostname = window && window.location && window.location.hostname;

    return (
        <>
            {studentsClass.students.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(student => {
                return (
                    <div key={`print-${student.id}`} className={cx(styles.student, styles.printSheet)}>
                        <div className={styles.studentHead}>
                            <div className={styles.studentHeadMain}>
                                <img className={styles.studentHeadLogo} width="100" src={wtrLogo} alt='' />
                                <h1 className={styles.studentHeadName}>{student.name}</h1>
                                <h2 className={styles.studentHeadPublished}>A Write To Raise Published Writer!</h2>
                                <p className='u-m-base-2'>Our class is writing a book with Brian Blessed OBE. We are celebrating our creativity, imagination and love of creative writing.</p>
                            </div>
                            <div className={styles.studentHeadBrian}>
                                <img width="220" src={brianBlessed} alt='' />
                            </div>
                        </div>
                        <div className={styles.studentFunds}>
                            <div className={styles.studentFundsImg}>
                                <img width="75" src={bookCover} alt='' />
                            </div>
                            <div className={styles.studentFundsMain}>
                                <h3 className={styles.subtitle}>Raising funds for our school</h3>
                                <p className={styles.lead}><b>Our school receives £4.99 for every book purchased!</b></p>
                                <p>Books cost just £7.99 each and will be delivered free of charge to the school ready for a hand-out celebration.</p>
                            </div>
                        </div>

                        <div className={styles.studentBook}>
                            <div className={styles.studentBookMain}>
                                <h3 className={styles.subtitle}>What’s in my personalised book?</h3>
                                <ul className={styles.studentBookList}>
                                    <li>My name on the cover</li>
                                    <li>My own story</li>
                                </ul>
                                <p>plus Brian's foreword, story start and the top stories as voted for by our class!</p>
                            </div>
                            <div className={styles.studentBookImg}>
                                <img width="238" src={bookContents} alt='' />
                            </div>
                        </div>

                        <div className={styles.studentOrder}>
                            <div className={styles.studentOrderMain}>
                                <h3 className={styles.studentOrderTitle}>Please order before 31st March 2023</h3>
                                <p>To purchase please visit the BoomWriter book store and enter the unique code for your child's book:</p>
                                <div className={styles.studentOrderUrl}>
                                    {hostname.includes('boom') ? (
                                        <img className={styles.studentOrderImg} src={buyQrImgBoom} alt='' />
                                    ) : <img className={styles.studentOrderImg} src={buyQrImg} alt='' />}
                                    <div>
                                        <h2 className={styles.studentOrderSubtitle}>{hostname.includes('boom') ? 'boomwriter.co.uk/buy' : 'go-write.co.uk/buy'}</h2>
                                        <p>My book code:</p>
                                        <h2 className={styles.studentOrderSubtitle}>{bookData.bookCode}-{student.storeCode}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.studentOrderSide}>
                                <div className={styles.studentOrderDelivery}>
                                    <p className={styles.lead}>Delivery</p>
                                    <p>Once the Write To Raise project has completed, books will be printed and shipped to your child’s school.</p>
                                </div>
                                <img className='u-m-auto' src={logo} width='100' alt='' />
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default WriteToRaiseLetters;