import React, { useCallback, useRef, useState } from 'react';
import EditorJs from 'react-editor-js';
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';

import { useMutation } from '@apollo/react-hooks';
import { EDIT_CLASS_BOOK } from '../../../../services/graphql';

import { CheckSquare, Square, AlertTriangle, AlertCircle, Printer } from 'react-feather';
import cx from 'classnames';
import styles from './PrepareForPrint.module.scss';

const PrepareForPrint = ({ 
    book,
    formErrors,
    onPrintOrder,
    setReviewEditOrder,
    orderEdited,
    confirmPrint,
    setConfirmPrint,
}) => {

    const [review, setReview] = useState({
        names: false,
        sendLetters: false,
        allEdits: false,
        happy: false,
        winnerEdit: book.isSingleChapter ? true : false
    });

    const [formBookErrors, setFormBookErrors] = useState([]);

    const editorJsRef = useRef(null);

    const [saveSettings, { loading: savingForward }] = useMutation(EDIT_CLASS_BOOK, {
        onCompleted: ({ editClassBook }) => {
            if (editClassBook.errors && editClassBook.errors.length !== 0) {
                setFormBookErrors(editClassBook.errors);
                return;
            }
        }
    });

    const onSaveEdit = useCallback(async () => {
        const savedData = await editorJsRef.current.save();
        if (savedData.blocks.length !== 0) {
            saveSettings({
                variables: {
                    classBookId: book.id,
                    foreword: JSON.stringify(savedData)
                }
            })
        }
    }, [saveSettings, book.id])


    return(
        <div className='row'>
            <div className="col-md-8 u-m-base-3">
                {
                    !book.canPrint && (
                        <div className="card-muted u-m-base-3">
                            <h1 className='icon-title heavy'><AlertTriangle size='30' className='u-text-danger u-m-right-2' /> Invoice Payment Required</h1>
                            <p>Sorry, our records show we are waiting for the invoice for this order to be paid. Please contact your finance manager to resolve this issue.</p>
                        </div>
                    )
                }

                <h2 className='u-m-base-3 heavy'>Publish this book</h2>
                <div className={styles.introAlert}>
                    <AlertCircle size="40" />
                    <p className='heavy'>We won’t print your class or any parent orders until you publish this book.</p>
                </div>

                <p className='u-m-base-2'>Please take a few moments to review the checklist and when you are ready send the book off to the printers!</p>
               
                <p>{orderEdited ? 'Order copies updated!' : 'We\'ve added 1 copy per pupil and one for the class.'}</p>
                <button onClick={() => setReviewEditOrder(true)} className='btn-reset u-text-tertiary link-underline u-m-base-3'>Review &amp; Edit order</button>

                <h2 className='heavy'>Foreword</h2>
                <div className='card-muted u-m-base-3'>
                    <EditorJs
                        data={JSON.parse(book.foreword)}
                        placeholder='Book foreword (required)'
                        instanceRef={instance => (editorJsRef.current = instance)}
                        enableReInitialize={false}
                        minHeight='0'
                        logLevel='WARN'
                    />
                    <Button className='u-m-top-2' outline disabled={savingForward} onClick={() => onSaveEdit()}>{savingForward ? 'Saving...' : 'Save'} </Button>
                    {formBookErrors.length !== 0 && <FormErrors errors={formBookErrors} />}
                </div>

                <div className={cx(styles.completedConfirmActions, 'u-m-base-3 u-m-top-1')}>
                    <div className='u-m-top-3'>
                        <div onClick={() => setReview({...review, names: !review.names})} className={styles.completedCheck}>
                            {review.names ? <CheckSquare /> : <Square />} <p>Pupil names spelt correctly</p>
                        </div>
                        {
                            !book.isSingleChapter && (
                                <div onClick={() => setReview({...review, winnerEdit: !review.winnerEdit})} className={styles.completedCheck}>
                                    {review.winnerEdit ? <CheckSquare /> : <Square />} <p>Winning chapters edited</p>
                                </div>
                            )
                        }
                        <div onClick={() => setReview({...review, allEdits: !review.allEdits})} className={styles.completedCheck}>
                            {review.allEdits ? <CheckSquare /> : <Square />} <p>{book.isSingleChapter ? 'All desired edits to all entries made' : 'All desired edits to last chapter entries made'}</p>
                        </div>
                        <div onClick={() => setReview({...review, sendLetters: !review.sendLetters})} className={styles.completedCheck}>
                            {review.sendLetters ? <CheckSquare /> : <Square />} <p>Send home parent letters</p>
                        </div>
                        <div onClick={() => setReview({...review, happy: !review.happy})} className={styles.completedCheck}>
                            {review.happy ? <CheckSquare /> : <Square />} <p>I'm happy for my book to be published</p>
                        </div>

                    </div>
                </div>
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <Button
                    onClick={() => setConfirmPrint(true)}
                    disabled={!(review.names && review.sendLetters && review.allEdits && review.happy && review.winnerEdit && book.canPrint)}
                >
                    Publish
                </Button>
            </div>
            <div className='col-md-4'>
                <h3 className='u-m-top-3 heavy'>Parent letters</h3>
                <p>Print and send home letters so parents know how to purchase their books.</p>
                <a 
                    href={`/book/${book.id}?printParentLetters=true`}
                    className='u-m-top-2 icon-title hidden-print'
                    target='_blank'
                    rel="noopener noreferrer"
                >
                    <Button outline className='icon-title'><Printer size='20' className='u-m-right-1' />Parent letters</Button>
                </a>
            </div>

            {
                confirmPrint && (
                    <Modal closeModal={() => setConfirmPrint(false)}>
                        <div className='u-text-center'>
                            <h3 className='u-m-base-3'>Are you sure? You can make no changes to the book after clicking to confirm.</h3>
                            <Button onClick={() => onPrintOrder()} className='u-m-right-2'>Yes</Button>
                            <Button outline onClick={() => setConfirmPrint(false)}>No</Button>
                        </div>
                    </Modal>
                )
            }
        </div>
    )
}

export default PrepareForPrint;