import React, { useContext, Fragment } from 'react';
import { Users } from 'react-feather';
import { AuthContext } from '../../services/auth/AuthProvider';
import ClassItem from './components/ClassItem';
import Class from '../Class';

const Dashboard = () => {

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;

    const renderClassList = () => {
        return(
            <Fragment>
                <div className='page-header'>
                    <div className='page-header__content'>
                        <h2 className='page-header__content__title'><Users /> Select a class</h2>
                    </div>
                </div>
                <div className="row">
                    {
                        teacher.studentsClasses.map(cls => (
                            <ClassItem key={cls.id}  cls={cls} />
                        ))
                    }
                </div>
            </Fragment>
        )
    }

    if (teacher.studentsClasses.length === 0) {
        return <p>No classes assigned yet.</p>
    }

    if (teacher.studentsClasses.length === 1) {
        return <Class classId={teacher.studentsClasses[0].id} />
    }

    return renderClassList();
}

export default Dashboard;
