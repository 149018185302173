import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Loader } from '@axeedge/go-teacher-components';

import { GET_CLASS_BOOK } from './services/graphql';
import BookHolder from './components/BookHolder';

const Book = ({ classBookId }) => {
    const { data, error, loading } = useQuery(GET_CLASS_BOOK, {
        pollInterval: 20000,
        variables: {
            classBookId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data.classBook && data.classBook.id) {
        return (
            <BookHolder bookData={data.classBook} />
        );
    }

    return null;
}

export default Book;
