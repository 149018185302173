import { gql } from 'apollo-boost';

export const CURRENT_PROMO_QUERY = gql`
query currentPromos {
    currentPromos {
        id
        name
        description
        isSingleChapter
        coverUrl
        previewUrl
        resourcesUrl
        isPromo
        isWriteToRaise
        promoOrderId
    }
}
`;

export const ADD_PROMO_PACK_MUTATION = gql`
mutation addPromoPackToClass($studentsClassId: ID!, $bookTemplateId: ID!) {
    addPromoPackToClass(studentsClassId: $studentsClassId, bookTemplateId: $bookTemplateId) {
        classBook {
            id
        }
        errors
    }
}
`;
