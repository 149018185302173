import React, { useState } from 'react';
import cx from 'classnames';
import { ChevronRight, ChevronDown } from 'react-feather';

import styles from './FaqItem.module.scss';


const FaqItem = ({ title, children }) => {
    const [active, setActive] = useState(false)

    return (
        <li className={styles.faq}>
            <h4 className={styles.faqTitle} onClick={() => setActive(active ? false : true)}>
                {title}
                {active ? <ChevronDown /> : <ChevronRight />}
            </h4>
            <div className={cx(styles.faqContent, { [styles.faqContentActive]: active })}>
                <div className={styles.faqContentInner}>{children}</div>
            </div>
        </li>
    )
}

export default FaqItem;