import React, { useContext, Fragment, useState } from 'react';
import { XCircle } from 'react-feather';
import styles from './AddPack.module.scss';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { GET_CLASS_PACKS, ASSIGN_PURCHASED_PACK_TO_CLASS_BOOK, GET_CLASS_BOOK } from '../../services/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Loader, Button, Modal } from '@axeedge/go-teacher-components';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

const AddPack = ({ book, close}) => {

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const [confirmAssign, setConfirmAssign] = useState()

    const { data, error, loading } = useQuery(GET_CLASS_PACKS, {
        variables: {
            id: book.studentsClass.id
        },
        fetchPolicy: 'nework-only'
    });

    const [assignPurchasedPackToClassBook, { loading: assigning }] = useMutation(ASSIGN_PURCHASED_PACK_TO_CLASS_BOOK, {
        onCompleted: ({ assignPurchasedPackToClassBook }) => {
            if (assignPurchasedPackToClassBook.errors && assignPurchasedPackToClassBook.errors.length !== 0) {
                alert(assignPurchasedPackToClassBook.errors[0]);
                return;
            } else {
                setConfirmAssign(false);
                close();
            }
        },
        refetchQueries: [{ query: GET_CLASS_BOOK, variables: { classBookId: book.id } }]
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass && teacher) {

        const { studentsClass } = data;
        const availableClassPacks = studentsClass.classPacks.filter(pack => pack.purchasedPack && !pack.bookTemplateCategory);

        return(
            <Fragment>
                <div className={styles.addPackHeader}>
                    <h2 className='heavy u-m-base-0'>Add or assign book pack</h2>
                    <button className='btn-reset' onClick={() => close()}><XCircle /></button>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <p className='u-m-base-3'>Purchase or use one of your existing Book Packs. A pack includes a personalised copy of the book for every pupil plus one extra for the class.</p>
                        <p className='u-m-base-3'>Book packs include free, fast shipping</p>

                        {availableClassPacks.length > 0 ? (
                            <Fragment>
                                <h2 className='heavy'>Use a pack</h2>
                                <p className='u-m-base-3'>You have {availableClassPacks.length} available {availableClassPacks.length > 1 ? 'packs' : 'pack'} for this class</p>
                                <Button onClick={() => setConfirmAssign(true)}>Use a pack</Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2 className='heavy'>Purchase a pack</h2>
                                {teacher.isSchoolAdmin ? (
                                    <>
                                        <p className='u-m-base-3'>You need a pack of at least {studentsClass.students.length} pupils for this class</p>
                                        <a href={`${getUrl(APPS.go_portal_teacher)}/gowrite/purchase/?book=${book.id}&classId=${studentsClass.id}&students=${studentsClass.students.length}`}><Button>Purchase now</Button></a>
                                    </>
                                ) : <p>Sorry, you don’t have any available packs. Please ask your school administrator to purchase a pack for {studentsClass.students.length} pupils and assign it to this class.</p> }
                            </Fragment>
                        )}
                    </div>
                </div>
                {confirmAssign && (
                    <Modal closeModal={() => setConfirmAssign(false)}>
                        <div className="u-text-center">
                            <h1>Are you sure?</h1>
                            <p className='u-m-base-3'>Assign one of your existing packs to this book.</p>
                            <Button
                                className='u-m-right-2'
                                onClick={() => assignPurchasedPackToClassBook({
                                    variables: {
                                        classBookId: book.id,
                                        purchasedPackId: availableClassPacks[0].purchasedPack.id
                                    }
                                })}
                                >{assigning? 'Loading' : 'Use  pack'}</Button> 
                             <Button outline onClick={() => setConfirmAssign(false)}>Cancel</Button>
                        </div>
                    </Modal>
                )}
            </Fragment>
        )
    }
}

export default AddPack;