import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import cx from 'classnames';
import styles from '../Promo.module.scss';
import NoAdminRights from './NoAdminRights';
import PurchaseDone from './PurchaseDone';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

import { useMutation } from '@apollo/react-hooks';
import { BOOK_PRICE } from '../../../services/constants';
import { PURCHASE_AND_ASSIGN_PROMO_TO_CLASSES, GET_PROMO_CLASSES } from '../services/graphql';
import moment from 'moment';

const PurchaseForm = ({ onBack, currentUser, school, currentPromo }) => {

    const [selectedClasses, setSelectedClasses] = useState([]);
    const [totals, setTotals] = useState(0);
    const [formErrors, setFormErrors] = useState([]);

    const [requestId, setRequestId] = useState(null);

    const acadYear = moment().toDate().getMonth() >= 8 ? moment().year() + 1 : moment().year();

    const onSelectClass = e => {
        if (selectedClasses.find(s => s.id === e.target.value)) {
            setSelectedClasses(selectedClasses.filter(s => s.id !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, school.classes.find(s => s.id === e.target.value)]);
        }
    }

    useEffect(() => {
        const totalsData = selectedClasses.reduce((acc, cur) => acc + cur.studentsCounter, 0);
        setTotals(totalsData * BOOK_PRICE);
    }, [selectedClasses, setTotals]);

    const [purchaseAndAssignPromo, { loading }] = useMutation(PURCHASE_AND_ASSIGN_PROMO_TO_CLASSES, {
        onCompleted: ({ purchaseAndAssignPromoPacksToClasses }) => {
            if (purchaseAndAssignPromoPacksToClasses.errors && purchaseAndAssignPromoPacksToClasses.errors.length !== 0) {
                setFormErrors(purchaseAndAssignPromoPacksToClasses.errors);
                return;
            } else if (purchaseAndAssignPromoPacksToClasses.requestId) {
                setRequestId(purchaseAndAssignPromoPacksToClasses.requestId);
            }
        },
        refetchQueries: [{ query: GET_PROMO_CLASSES, variables: { schoolId: school.id } }],
    });


    const schema = Yup.object().shape({
        tos: Yup.boolean().oneOf([true], "You must agree to the terms and conditions"),
        authority: Yup.boolean().oneOf([true], "You must have the authority to purchase the book packs for your school"),
    });

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        validationSchema: schema
    });


    const onSubmit = () => {
        purchaseAndAssignPromo({
            variables: {
                studentClassesIds: selectedClasses.map(s => s.id),
                schoolId: currentUser.school.id,
            }
        });
    }

    if (currentUser && !currentUser.isSchoolAdmin) {
        return <NoAdminRights onBack={onBack} schoolId={currentUser.school.id} />
    }

    return (
        <>
            <div className="page-header">
                <div className="page-header__content">
                    <h2 className="page-header__content__title">
                        <button onClick={onBack} className='btn-reset u-m-right-2'><ArrowLeft /></button>
                        {requestId ? 'Thanks for your purchase' : 'Purchase books'}</h2>
                </div>
            </div>
            <div className='card card-mobile'>
                {requestId ? <PurchaseDone requestId={requestId} currentPromo={currentPromo} /> : (
                    <>
                        <h3 className='heavy'>Which classes will take part?</h3>
                        <div className={styles.klsWrapper}>
                            {school.classes.filter(kls => !kls.hasCurrentPromoBook && kls.studentsCounter > 0 && kls.academicYear === acadYear).map(kls => (
                                <div key={kls.id} className={cx(styles.klsItem, 'basic-form__group basic-form__group--check')}>
                                    <input value={kls.id} onChange={(e) => onSelectClass(e)} className="basic-form__check-box" type="checkbox" id={kls.id} />
                                    <label htmlFor={kls.id} className="basic-form__check-label mr-1">{kls.name}</label>
                                </div>
                            ))}
                        </div>
                        {
                            school.classes.filter(kls => kls.hasCurrentPromoBook).length > 0 && (
                                <>
                                    <h3 className='heavy u-m-top-2'>These classes already have the {currentPromo.name} book</h3>
                                    <div className={styles.klsWrapper}>
                                        {school.classes.filter(kls => kls.hasCurrentPromoBook).map(kls => (
                                            <div key={kls.id} className={cx(styles.klsItem, 'basic-form__group basic-form__group--check')}>
                                                <input checked value={kls.id} disabled className="basic-form__check-box" type="checkbox" id={kls.id} />
                                                <label htmlFor={kls.id} className="basic-form__check-label mr-1">{kls.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )
                        }

                        <div className="row u-m-top-3 u-m-base-3">
                            <div className="col-md-8">
                                <h3 className='heavy'>Your class pack purchases</h3>
                                {selectedClasses.length > 0 ? (
                                    <ul>
                                        {selectedClasses.map(el => (
                                            <li key={`selected-${el.id}`} className={styles.klsSelected}>
                                                <div>{el.studentsCounter} Books + 2 Free Class Copies</div>
                                                <div className={styles.klsSelectedPrice}>£{(el.studentsCounter * BOOK_PRICE).toFixed(2)}</div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (<p className='u-m-base-2'>No classes selected</p>)}

                                <ul className={styles.totals}>
                                    <li>
                                        <div>Books Total</div>
                                        <span>£{totals.toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <div>Shipping</div>
                                        <span>£0.00</span>
                                    </li>
                                    <li className='heavy'>
                                        <div>Total</div>
                                        <span>£{totals.toFixed(2)}</span>
                                    </li>
                                </ul>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="basic-form__group basic-form__group--check">
                                        <input
                                            name="tos"
                                            className="basic-form__check-box"
                                            ref={register}
                                            type="checkbox"
                                            id="tos"
                                        />
                                        <label className="basic-form__check-label" htmlFor="tos">I have read and accept the terms and conditions</label>
                                    </div>
                                    {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}
                                    <div className="basic-form__group basic-form__group--check">
                                        <input
                                            name="authority"
                                            className="basic-form__check-box"
                                            ref={register}
                                            type="checkbox"
                                            id="authority"
                                        />
                                        <label className="basic-form__check-label" htmlFor="authority">I have purchasing authority for this purchase</label>
                                    </div>
                                    {errors.authority && <p className='basic-form__hint'>{errors.authority.message}</p>}
                                    {formErrors && formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                    <p className='lead heavy'>Note: Payment must be made before your books are printed</p>
                                    <Button className='u-m-top-2' disabled={totals === 0 || loading}>Purchase &amp; Assign To Classes</Button>
                                </form>
                            </div>

                            <div className="col-md-4">
                                <div className='panelLight'>
                                    <h3>Finance Officer Details</h3>
                                    <p>An invoice will be sent to the following finance contact:</p>
                                    {school.billingProfile ? (
                                        <>
                                            <p>{school.billingProfile.contactPerson}</p>
                                            <p>{school.billingProfile.contactEmail}</p>
                                            <p>{school.billingProfile.contactPhone}</p>
                                        </>
                                    ) :
                                        <>
                                            <p>{school.address}</p>
                                            <p>{school.address2}</p>
                                            <p className='u-m-base-1'>{school.city}</p>
                                            <a className='link-underline' href={`${getUrl(APPS.go_portal_teacher)}/settings/billing`}>Add Billing profile</a>
                                        </>
                                    }
                                </div>
                                <p className='small u-m-top-1'>You can edit these details in your school settings</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default PurchaseForm;