import React from 'react';

import FaqItem from '../../../../components/FaqItem';
import { Button } from '@axeedge/go-teacher-components';
import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import ResourcesList from '../ResourcesList';

import cx from 'classnames';
import styles from './BookSettings.module.scss';
import ImageFadeIn from 'react-image-fade-in';
import wtrBook from '../../../../images/book/wtr_setup.png';
import bookContents from '../../../../images/book/book_contents.png';
import { CheckCircle } from 'react-feather';


const WriteToRaiseBookSettings = ({ book, setShowPrintableList, startChapter, setSelectedChapter }) => {
    return (
        <>
            <div className={styles.settingsContent}>
                <div className='card card-mobile'>
                    <div className='u-m-base-3'>
                        <h1 className='u-m-base-3'>Getting started on your Write to Raise book project.</h1>
                        <p className='heavy u-m-base-2 lead'>Step 1</p>
                        <p className='u-m-base-1'>Find out more about Write to Raise and how you can raise valuable funds for your school.</p>
                        <p className='u-m-base-2'> boomwriter.co.uk/write-to-raise</p>

                        <ul className='u-m-base-2'>
                            <FaqItem title='How it works?'>
                                <h3 className='heavy u-m-top-2'>The Write To Raise Book is simple. </h3>
                                <ul className={styles.wtrNumberList}>
                                    <li>Read the story start, penned by Brian himself, and your children plan their own short stories based upon it. Follow our materials for more details here:</li>
                                    <li>The children write their stories. Use our slides and adapt them to support your pupils here:</li>
                                    <li>When writing and editing is complete, the children vote for their favourite using our StarCasting voting system on the app.</li>
                                    <li>Announce the winner, check you're happy with the book and hit publish!</li>
                                </ul>
                            </FaqItem>
                            <FaqItem title='How do we raise money?'>
                                <p className='u-m-base-1'>For each book purchased by parents, the school receives <b>£4.99</b>. So this is a fantastic opportunity to raise valuable funds for school.</p>
                                <p className='u-m-base-2'> <a className='u-text-primary link-underline' href={`/book/${book.id}?printParentLetters=true`} target='_blank' rel="noopener noreferrer">
                                    Download the Parent Letters here
                                </a></p>
                            </FaqItem>

                            <FaqItem title='What is included in the book?'>
                                <h3 className='heavy u-m-top-2'>Each pupil’s personalised book contains:</h3>
                                <div className={styles.wtrBookContents}>
                                    <div>
                                        <ul className={styles.wtrBookContentsList}>
                                            <li>Personalised cover featuring their name</li>
                                            <li>Foreword by Brian Blessed</li>
                                            <li>Foreword by you/school (optional)</li>
                                            <li>Class winner </li>
                                            <li>Runner Up Entries</li>
                                            <li>Their own entry</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ImageFadeIn src={bookContents} atl='' />
                                    </div>
                                </div>
                            </FaqItem>
                            <FaqItem title="Any Questions?">
                                <p>Please contact us support@boomhub.app and we will be happy to help</p>
                            </FaqItem>
                        </ul>

                        <p className='heavy u-m-base-2 lead'>Step 2: Planning & Prep</p>

                        <p>Download and review the guides & resources that will help you to successfully complete this BoomWriter book:</p>

                        <div className={styles.wtrPlanning}>
                            <div className={styles.wtrPlanningTodo}>
                                <ul className='u-m-base-3'>
                                    <li><CheckCircle /> Book Planning Guide</li>
                                    <li><CheckCircle /> Adaptable Lesson Slides</li>
                                    <li><CheckCircle /> How to use BoomWriter</li>

                                </ul>
                                {book.bookTemplate.resourcesUrl && (
                                    <a className={styles.wtrDownload} href={book.bookTemplate.resourcesUrl} download>
                                        Download and view
                                    </a>
                                )}
                            </div>
                            <div className={styles.wtrPlanningEvent}>
                                <p className='heavy lead'>Join a live event</p>
                                <p className='heavy'>Plan for your class to join one of our live events that focus on deconstructing the story start and idea generation.</p>
                            </div>
                        </div>
                        <p className='heavy u-m-base-2 lead'>Step 3: Start Writing & Send Parent Letters</p>
                        <p>When you are ready, set up writing and send home the included parent letters so parents can pre-purchase their books.</p>
                    </div>

                    <div className='u-m-base-3 u-p-base-2'>
                        {
                            book.isSingleChapter && book.chapters[0].status === CHAPTER_STATUS.new ? (
                                <Button disabled={!book.title} onClick={() => startChapter({ variables: { bookChapterId: book.chapters[0].id } })}>Setup Writing</Button>
                            ) :
                                book.chapters[1] && book.chapters[1].status === CHAPTER_STATUS.new ?
                                    <Button disabled={!book.title} onClick={() => setSelectedChapter(0)}>Setup Chapter 1</Button> :
                                    <Button outline onClick={() => setSelectedChapter(book.status <= BOOK_STATUS.completed ? book.currentChapter : -1)}>Continue</Button>
                        }
                    </div>
                </div>
            </div>

            <div className={styles.sidebar}>
                <ImageFadeIn className={styles.wtrBrianImg} src={wtrBook} alt='' />
                <div className={cx(styles.sidebarContent, styles.sidebarWtr)}>
                    <div className={styles.sidebarActions}>
                        <ResourcesList book={book} setShowPrintableList={setShowPrintableList} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default WriteToRaiseBookSettings