import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CheckCircle } from 'react-feather';
import ImageFadeIn from 'react-image-fade-in';

import { Button } from '@axeedge/go-teacher-components';
import { AWARD_GEM_MUTATION } from './services/graphql';

import gem from './images/gem.svg';
import styles from './GemButton.module.scss';

const GemButton = ({ fullWidth, studentId }) => {
    const [gemAwarded, setGemAwarded] = useState(false);

    const [awardGem, { loading }] = useMutation(AWARD_GEM_MUTATION, {
        variables: {
            studentId
        },
        onCompleted: ({ addRewardGemToStudent }) => {
            if (addRewardGemToStudent.errors && addRewardGemToStudent.errors.length !== 0) {
                alert('There was an error awarding the gem. Please try again');
                return;
            }
            if (addRewardGemToStudent.student && addRewardGemToStudent.student.id) {
                setGemAwarded(true);
            }
        }
    });

    return (
        <Button
            className={styles.gemButton}
            onClick={() => !gemAwarded && awardGem()}
            fullWidth={fullWidth}
            outline
        >
            <ImageFadeIn src={gem} alt='' className={styles.gemButtonImg} />
            {
                gemAwarded ? <CheckCircle className={styles.gemButtonConfirmed} /> : loading ? 'Awarding...' : 'Award a gem'
            }
        </Button>
    )
}

export default GemButton;

