import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import { Book, ArrowLeft, Check } from 'react-feather';

import { GET_BOOK_TEMPLATE_CATEGORIES } from '../../services/graphql';
import PackBook from '../PackBook';

import cx from 'classnames';
import styles from './SelectBookType.module.scss';


const SelectBookType = ({ classId, classPackId }) => {

    const [selectedTemplateCateg, setSelectedTemplateCateg] = useState();

    const { data, error, loading } = useQuery(GET_BOOK_TEMPLATE_CATEGORIES);

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.bookTemplateCategories) {
        return(
             <Fragment>
                <div className='page-header'>
                    <div className='page-header__content'>
                        <div className='page-header__content__inner'>
                            <h1 className='page-header__content__title'><Book /> {(selectedTemplateCateg && selectedTemplateCateg.name) || 'Choose your book type'}</h1>
                            {
                                selectedTemplateCateg && (
                                    <Fragment>
                                        <p className='page-header__content__subtitle'>{selectedTemplateCateg.description}</p>
                                        <p className='u-m-top-1'>Choose your {selectedTemplateCateg.name} book project from the options below.</p>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
                {
                    !selectedTemplateCateg ? (
                        <div className='row'>
                            <div className="col-md-6 col-lg-5">
                                <div className="card-mobile">
                                    <p className='u-m-base-2'>Select which type of book you would like this class to do:</p>
                                    <ul className='list-group u-m-base-2'>
                                        {
                                            data.bookTemplateCategories.map(bookTemplateCateg => (
                                                <li className='list-item' key={`bt-${bookTemplateCateg.id}`}>
                                                    <button
                                                        type='button'
                                                        className={cx('btn-reset link-reverse', styles.templateSelectBtn)}
                                                        onClick={() => setSelectedTemplateCateg(bookTemplateCateg)}
                                                    >
                                                        {bookTemplateCateg.name}
                                                        <Check />
                                                    </button> 
                                                </li>
                                            ))
                                        } 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            <p className='u-m-base-2 u-text-white u-text-sm-dark'><button className='btn-reset icon-title' onClick={() => setSelectedTemplateCateg(null)}><ArrowLeft /> Back</button> </p>
                            <div className='row'>
                                {
                                    selectedTemplateCateg.bookTemplates.map(book => {
                                        return <PackBook key={`book-${book.id}`} book={book} bookTemplateCategoryId={selectedTemplateCateg.id} classId={classId} classPackId={classPackId} />
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                }
             </Fragment>
        )
     }

    return null
}

export default SelectBookType;