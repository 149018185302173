import React, { Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { Button } from '@axeedge/go-teacher-components';

import { EDIT_TASK_MUTATION, GET_TASK_QUERY, DELETE_TASK_MUTATION } from './services/graphql';
import {GET_CLASS_QUERY} from '../../services/class/graphql'
import BookHeader from '../Book/components/BookHeader';
import TaskForm from './components/TaskForm';
import Sidebar from './components/Sidebar';

import styles from './Task.module.scss';

const Task = ({ classBookId }) => {
    const { data, loading } = useQuery(GET_TASK_QUERY, {
        pollInterval: 20000,
        variables: {
            classBookId
        }
    });

    const [editTask, { loading: editing }] = useMutation(EDIT_TASK_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: ({ editWritingActivity }) => {
            if (!editWritingActivity.classBook && !editWritingActivity.classBook.id) {
                alert('There was an error, please try again.');
            }
            if (editWritingActivity.errors && editWritingActivity.errors.length !== 0) {
                alert(editWritingActivity.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_TASK_QUERY, variables: { classBookId } }]
    });

    const [deleteTask, {loading: deleting}] = useMutation(DELETE_TASK_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: ({ deleteClassBook }) => {
            if (deleteClassBook.errors && deleteClassBook.errors.length !== 0) {
                alert(deleteClassBook.errors[0]);
                return;
            }
            if (deleteClassBook.deleted) {
                navigate(`/class/${data && data.classBook.studentsClass.id}`, { state: { deleted: true, }});
                return;
            }
            alert('There was an error, please try again.');
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: data && data.classBook.studentsClass.id } }]
        
    })

    const onDeleteTask = (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Writing Activity?');
        if (confirmDelete) {
            deleteTask({
                variables: {
                    classBookId: id
                }
            })
        }
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (data && data.classBook && data.classBook.id) {
        const { classBook: task } = data;
        return (
            <Fragment>
                <BookHeader classId={task.studentsClass.id} title={task.title} showBackBtn={true} />
                <div className={styles.task}>
                    <div className={styles.taskContent}>
                        <TaskForm 
                            loading={editing} 
                            task={{
                                id: classBookId, 
                                title: task.title, 
                                description: task.foreword, 
                                resources: task.bookTemplate.resources, 
                                castingEnabled: task.castingEnabled,
                                classId: task.studentsClass.id,
                                status: task.chapters[0].status,
                                vocabularyWords: task.chapters[0].vocabularyWords
                            }}
                            onSubmitForm={editTask}
                        />
                        {task.status < BOOK_STATUS.started && <Button className='u-m-base-2' onClick={() => onDeleteTask(classBookId)} disabled={deleting && true}>{deleting ? 'Deleting Task' : 'Delete Task'}</Button>}
                    </div>
                    <Sidebar task={task} classBookId={classBookId} />
                </div>
                
            </Fragment>
        )
    }

    return null;
}

export default Task;
