import React from 'react';

import StudentsEntriesList from '../../../StudentsEntriesList';

const Writing = ({ chapter }) => {
 
    return (
        <div className="chapter__writing">
            <h1 className='u-m-base-3'>Writing</h1>
            <StudentsEntriesList 
                award={true}
                eligible={chapter.classBook.castingEnabled}
                status={chapter.status}
                entries={chapter.studentEntries}
                visibleInCasting={chapter.classBook.castingEnabled}
                writing={true}
            />
          
        </div>
    );
}

export default Writing;